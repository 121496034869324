import React from 'react';
import PropTypes from "prop-types";

const ViewDetails = ({label, value, showLabel, labelClass, wrapperClass, options }) => {

    if (!showLabel) {
        labelClass += " is-visuallyhidden";
    }

    if(options && options.length > 0)
    {
        const option = options.filter(o => o.value.toString() === value);
        if (option.length !== 0)
            value = option[0].text;
        else
            value = "";
    }

    if(value === "")
        value = "Not specified";

    return (
        <div className={"row " + wrapperClass}>
            <label className={"columns column-1 " + labelClass}><strong>{label}</strong></label>
            <div className="columns column-2">{value}</div>
        </div>
    );
};

ViewDetails.propTypes = {
    label: PropTypes.string,
    value: PropTypes.string,
    showLabel: PropTypes.bool,
    labelClass: PropTypes.string,
    wrapperClass: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object)
};

ViewDetails.defaultProps = {
    labelClass: "small-12",
    wrapperClass: "",
    value: "",
    showLabel: true
};

export default ViewDetails;