import * as actionTypes from "../constants/actionTypes";
import initialState from "./initialState";

export default function  accountReducer(state = initialState.account, action) {
    switch(action.type){
        case actionTypes.EMAILCHECK_AJAX_SUCCESS:
            return Object.assign({}, state, { emailExists: action.emailInfo.emailCheckResults, Email: action.emailInfo.Email });
        case actionTypes.LOAD_USER_PROFILE_AJAX_SUCCESS:
        case actionTypes.CREATE_PROFILE_AJAX_SUCCESS:
        case actionTypes.UPDATE_PROFILE_AJAX_SUCCESS:
            return Object.assign({}, state, action.userInfo);
        default:
            return state;
    }
}