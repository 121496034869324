import React from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as generalProfileDataActions from "../../actions/generalProfileDataActions";
import Toastr from "toastr";
import ToastrOptions from "../../constants/toastr";

export class LoadGeneralProfileData extends React.Component {
    constructor(props) {
        super(props);

        Toastr.options = ToastrOptions.Error();
    }

    componentWillMount() {
        ["Divisions", "Roles", "Courses", "Districts"].map( generalDataName => {
            if (this.props.generalProfileData[generalDataName.toLowerCase()].length === 0) {
                this.props.actions["load" + generalDataName]()
                    .catch(error => {
                        if(error !== "")
                            Toastr.error(error);
                    });
            }
        });
    }

    render() {
        return this.props.children;
    }
}

LoadGeneralProfileData.propTypes = {
    actions: PropTypes.object,
    generalProfileData: PropTypes.object,
    children: PropTypes.object
};

function mapStateToProps(state) {
    return {
        generalProfileData: state.generalProfileData
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(generalProfileDataActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoadGeneralProfileData);
