import authenticationApi from "../api/authenticateApi";
import { beginAjaxCall, ajaxCallError } from "./ajaxStatusActions";
import * as actionTypes from "../constants/actionTypes";
import storage from "../components/shared/localStorage";
import {checkEmailSuccess} from "./accountActions";
import {saveSearchCriteria} from "./manageUserActions";
import * as userConstants from "../constants/userConstants";
import Toastr from "toastr";
import * as config from "../constants/config";
import ToastrOptions from "../constants/toastr";

export function loginSuccess(isLoggedIn, isAdmin, role) {
    const loginData = { isLoggedIn: isLoggedIn, isAdmin: isAdmin, role: role};
    return { type: actionTypes.LOGIN_AJAX_SUCCESS, loginData };
}

export function attemptLogin(loginViewModel) {
    return dispatch => {
        dispatch(beginAjaxCall());
        return authenticationApi.attemptLogin(loginViewModel)
            .then((loginInfo) => {
                storage.persistLogin(loginInfo.access_token, loginInfo.at_expires_in, loginInfo.refresh_token, loginInfo.rt_expires_in, loginInfo.isadmin, loginInfo.role, loginInfo.divisionId, loginInfo.districtId);
                dispatch(loginSuccess(true, loginInfo.isadmin, loginInfo.role));
            })
            .catch(error => {
                storage.logout();
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function refreshLogin() {
    return dispatch => {
        dispatch(beginAjaxCall());
        return authenticationApi.refreshToken(storage.getRefreshToken())
            .then((loginInfo) => {
                storage.persistLogin(loginInfo.access_token, loginInfo.at_expires_in, loginInfo.refresh_token, loginInfo.rt_expires_in, loginInfo.isadmin, loginInfo.role, loginInfo.divisionId, loginInfo.districtId);
                dispatch(loginSuccess(true, loginInfo.isadmin, loginInfo.role));
            })
            .catch(error => {
                storage.logout();
                dispatch(loginSuccess(false, false, "user"));
                dispatch(ajaxCallError(error));
                Toastr.options = ToastrOptions.Error();
                Toastr.error(`Your session has expired.  Please login again.  If the problem persists, contact ${config.supportEmail}.`, '', {
                    onHidden: () => {
                        window.location.href = "/";
                    }
                });
                throw (error);
            });
    };
}

export function refreshAdminLogin() {
    return dispatch => {
        dispatch(beginAjaxCall());
        return authenticationApi.refreshToken(storage.getAdminRefreshToken())
            .then((loginInfo) => {
                storage.removeAdminRefreshToken();
                storage.persistLogin(loginInfo.access_token, loginInfo.at_expires_in, loginInfo.refresh_token, loginInfo.rt_expires_in, loginInfo.isadmin, loginInfo.role, loginInfo.divisionId, loginInfo.districtId);
                dispatch(loginSuccess(true, loginInfo.isadmin, loginInfo.role));
            })
            .catch(error => {
                storage.logout();
                dispatch(loginSuccess(false, false, "user"));
                dispatch(ajaxCallError(error));
                Toastr.options = ToastrOptions.Error();
                Toastr.error(`Your session has expired.  Please login again.  If the problem persists, contact ${config.supportEmail}.`, '', {
                    onHidden: () => {
                        window.location.href = "/";
                    }
                });
                throw (error);
            });
    };
}

export function logOff() {
    return dispatch => {
        storage.logout();
        dispatch(saveSearchCriteria(userConstants.generateEmptyUserSearchCriteria()));
        dispatch(loginSuccess(false, false, "user"));
        const emailInfo = { emailCheckResults: false, Email: "" };
        dispatch(checkEmailSuccess(emailInfo));
    };
}

export function setRedirectUrl(redirectUrl) {
    return {type: actionTypes.LOGIN_SET_RETURNURL, redirectUrl};
}

export function loginAsUser(userId) {
    return dispatch => {
        dispatch(beginAjaxCall());
        return authenticationApi.getUserTokenAsAdmin(userId)
            .then((loginInfo) => {
                storage.persistAdminRefreshToken();
                storage.persistLogin(loginInfo.access_token, loginInfo.at_expires_in, loginInfo.refresh_token, loginInfo.rt_expires_in, loginInfo.isadmin, loginInfo.role, loginInfo.divisionId, loginInfo.districtId);
                dispatch(loginSuccess(true, loginInfo.isadmin, loginInfo.role));
            })
            .catch(error => {
                storage.logout();
                dispatch(loginSuccess(false, false, "user"));
                dispatch(ajaxCallError(error));
                Toastr.options = ToastrOptions.Error();
                Toastr.error(`Your session has expired.  Please login again.  If the problem persists, contact ${config.supportEmail}.`, '', {
                    onHidden: () => {
                        window.location.href = "/";
                    }
                });
                throw (error);
            });
    };
}


