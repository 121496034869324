let users = null;

function getDivisionData() {
    if(users === null) {
        users = [
            {
                Id: 7,
                Email: "testAccount@fcim.org",
                FirstName: "Fcim",
                LastName: "Test",
                Role: "User",
                RoleId: 1,
                DivisionId: 4,
                Division: "D Division",
                CourseName: "Safety Awareness Training",
                CourseCompletionDate: "10/15/2017"
            },
            {
                Id: 8,
                Email: "min@fcim.org",
                FirstName: "",
                LastName: "",
                Role: "User",
                RoleId: 1,
                DivisionId: 4,
                Division: "D Division",
                CourseName: "",
                CourseCompletionDate: ""
            },
            {
                Id: 9,
                Email: "canopy@fcim.org",
                FirstName: "Betty",
                LastName: "Smith",
                Role: "User",
                RoleId: 1,
                DivisionId: 3,
                Division: "C Division",
                CourseName: "Safety Awareness Training",
                CourseCompletionDate: ""
            },
            {
                Id: 10,
                Email: "GovCharter@fcim.org",
                FirstName: "Tommy",
                LastName: "Private",
                Role: "User",
                RoleId: 1,
                DivisionId: 3,
                Division: "C Division",
                CourseName: "Safety Awareness Training",
                CourseCompletionDate: "6/13/2017"
            },
            {
                Id: 11,
                Email: "home@fcim.org",
                FirstName: "Frank",
                LastName: "Gifford",
                Role: "User",
                RoleId: 1,
                DivisionId: 3,
                Division: "C Division",
                CourseName: "Safety Awareness Training",
                CourseCompletionDate: "5/2/2017"
            },
            {
                Id: 12,
                Email: "HomeEduc@fcim.org",
                FirstName: "Jane",
                LastName: "Doe",
                Role: "User",
                RoleId: 1,
                DivisionId: 2,
                Division: "B Division",
                CourseName: "Safety Awareness Training",
                CourseCompletionDate: ""
            }
        ];
    }

    return users;
}

export {getDivisionData};

function getSummaryReportData() {
    return {
        DateRange: "",
        TotalUsersRegistered: 7,
        TotalUsersAccessed: 3,
        ProfileDataSummaries: [
            {
                Question: "Division",
                QuestionOptionsWithTotals: {
                    "A Division": 0,
                    "B Division": 1,
                    "C Division": 2,
                    "D Division": 4
                }
            }
        ],
        CourseDataSummaries: [
            {
                CourseName: "Security Awareness Training",
                GeneralTotalSummaryInfo: {
                    TotalUsersEnrolled: 6,
                    TotalUsersCompleted: 1
                },
                KnowledgeCheckTotalSummaries: [
                    {
                        SectionId: "094E",
                        AverageNumberOfAttempts: 1.5,
                        AveragePassingScore: 95,
                        TotalNumberOfAttempts: 3,
                        TotalNumberPassed: 2,
                        HeaderResponses: ["A","B","C","D"],
                        ItemResponses: [
                            {
                                ItemId: "1",
                                TotalResponses: "12",
                                Responses: [
                                    {
                                        Value: "A",
                                        Total: "8",
                                        Percent: "67%"
                                    },
                                    {
                                        Value: "B",
                                        Total: "0",
                                        Percent: "0%"
                                    },
                                    {
                                        Value: "C",
                                        Total: "1",
                                        Percent: "8%"
                                    },
                                    {
                                        Value: "D",
                                        Total: "3",
                                        Percent: "25%"
                                    }
                                ]
                            },
                            {
                                ItemId: "2",
                                TotalResponses: "12",
                                Responses: [
                                    {
                                        Value: "A",
                                        Total: "0",
                                        Percent: "0%"
                                    },
                                    {
                                        Value: "B",
                                        Total: "8",
                                        Percent: "67%"
                                    },
                                    {
                                        Value: "C",
                                        Total: "1",
                                        Percent: "8%"
                                    },
                                    {
                                        Value: "D",
                                        Total: "3",
                                        Percent: "25%"
                                    }
                                ]
                            }
                            ,
                            {
                                ItemId: "3",
                                TotalResponses: "12",
                                Responses: [
                                    {
                                        Value: "A",
                                        Total: "0",
                                        Percent: "0%"
                                    },
                                    {
                                        Value: "B",
                                        Total: "8",
                                        Percent: "67%"
                                    },
                                    {
                                        Value: "C",
                                        Total: "3",
                                        Percent: "25%"
                                    },
                                    {
                                        Value: "D",
                                        Total: "1",
                                        Percent: "8%"
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        SectionId: "054E",
                        AverageNumberOfAttempts: 0,
                        AveragePassingScore: 0,
                        TotalNumberOfAttempts: 0,
                        TotalNumberPassed: 0,
                        HeaderResponses: ["A","B","C","D"],
                        ItemResponses: [
                            {
                                ItemId: "1",
                                TotalResponses: "12",
                                Responses: [
                                    {
                                        Value: "A",
                                        Total: "8",
                                        Percent: "67%"
                                    },
                                    {
                                        Value: "B",
                                        Total: "0",
                                        Percent: "0%"
                                    },
                                    {
                                        Value: "C",
                                        Total: "1",
                                        Percent: "8%"
                                    },
                                    {
                                        Value: "D",
                                        Total: "3",
                                        Percent: "25%"
                                    }
                                ]
                            },
                            {
                                ItemId: "2",
                                TotalResponses: "12",
                                Responses: [
                                    {
                                        Value: "A",
                                        Total: "0",
                                        Percent: "0%"
                                    },
                                    {
                                        Value: "B",
                                        Total: "8",
                                        Percent: "67%"
                                    },
                                    {
                                        Value: "C",
                                        Total: "1",
                                        Percent: "8%"
                                    },
                                    {
                                        Value: "D",
                                        Total: "3",
                                        Percent: "25%"
                                    }
                                ]
                            }
                            ,
                            {
                                ItemId: "3",
                                TotalResponses: "12",
                                Responses: [
                                    {
                                        Value: "A",
                                        Total: "0",
                                        Percent: "0%"
                                    },
                                    {
                                        Value: "B",
                                        Total: "8",
                                        Percent: "67%"
                                    },
                                    {
                                        Value: "C",
                                        Total: "3",
                                        Percent: "25%"
                                    },
                                    {
                                        Value: "D",
                                        Total: "1",
                                        Percent: "8%"
                                    }
                                ]
                            },
                            {
                                ItemId: "4",
                                TotalResponses: "12",
                                Responses: [
                                    {
                                        Value: "TRUE",
                                        Total: "8",
                                        Percent: "67%%"
                                    },
                                    {
                                        Value: "FALSE",
                                        Total: "4",
                                        Percent: "33%"
                                    },
                                    {
                                        Value: "",
                                        Total: "-",
                                        Percent: "-"
                                    },
                                    {
                                        Value: "",
                                        Total: "-",
                                        Percent: "-"
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        SectionId: "102E",
                        AverageNumberOfAttempts: 0,
                        AveragePassingScore: 0,
                        TotalNumberOfAttempts: 0,
                        TotalNumberPassed: 0,
                        HeaderResponses: [],
                        ItemResponses: []
                    }
                ]
            }
        ]
    };
}
export {getSummaryReportData};