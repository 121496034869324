import queryString from "querystring";

function toDangerouslySetHtml(htmlString){
    let toReturn = "";
    if(htmlString)
        toReturn = htmlString;

    return { __html: toReturn };
}

export {toDangerouslySetHtml};

export const CheckboxType = "checkbox";
export const RadioType = "radio";
export const SelectListType = "select-one";

function isStringInDelimitedList(delimitedList, itemToSearchFor) {
    if(delimitedList === null || delimitedList === "" || delimitedList === undefined)
        return false;

    const allItems = delimitedList.split(",");
    return (allItems.findIndex(i => i == itemToSearchFor) > -1);
}

export {isStringInDelimitedList};

function adjustDelimitedList(delimitedList, item, isAdd) {
    let newList = "";
    const allItems = (delimitedList === null || delimitedList === "" || delimitedList === undefined) ? [] : delimitedList.split(",");
    let isFound = false;
    for(let i = 0; i < allItems.length; i++) {
        if(allItems[i] !== item)
            newList += allItems[i] + ",";
        else {
            isFound = true;
            if(isAdd)
                newList += allItems[i] + ",";
        }
    }

    if(!isFound && isAdd && item !== null && item !== "" && item !== undefined)
        newList += item + ",";

    // remove the last comma
    if(newList.length > 0)
        newList = newList.substring(0, newList.length - 1);

    return newList;
}

export {adjustDelimitedList};

function createRedirectUrl(searchStr, hash) {
    let url = "";

    if(searchStr && searchStr.charAt(0) === "?")
        searchStr = searchStr.substr(1);

    let parsed = queryString.parse(searchStr);

    if(parsed.redirectUrl) {
        url = parsed.redirectUrl;
        if(hash)
            url += hash;
    }

    return url;
}
export {createRedirectUrl};