import * as actionTypes from "../constants/actionTypes";
import initialState from "./initialState";

export default function userReducer(state = initialState.user, action) {
    switch(action.type){
        case actionTypes.LOAD_USERS_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    searchResults: [...action.data.Data],
                    totalRecords: action.data.TotalRecords
                });
        case actionTypes.SAVE_SEARCH_CRITERIA:
            return Object.assign({}, state, { searchCriteria: action.criteria});
        default:
            return state;
    }
}