export function isObjectNullOrEmpty(obj) {
    return !(obj && Object.getOwnPropertyNames(obj).length !== 0);
}

export function isString(value) {
    return (typeof value === 'string' && value.constructor === String);
}

export function optionTextInOptionsArray(array, option) {
    if(option === null || option === undefined) return "";

    const optionValue = option.toString().toLowerCase();

    for (let i = 0; i < array.length; i++) {
        const arrayValue = array[i].value.toString().toLowerCase();
        if (arrayValue === optionValue)
            return array[i].text;
    }

    return "";
}