import React from 'react';
import PropTypes from "prop-types";

const Button = ({ name, label, onClick, btnClass, disabled, btnType }) => {
    return (
        <button id={name} type={btnType} onClick={onClick} className={btnClass} disabled={disabled}><span />{label}</button>
    );
};

Button.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    btnClass: PropTypes.string,
    disabled: PropTypes.bool,
    btnType: PropTypes.string
};

Button.defaultProps = {
    disabled: false,
    btnType: "submit"
};

export default Button;
