import React from "react";
import PropTypes from "prop-types";
import DivisionReportRow from "./divisionReportRow";
import Button from "../common/buttons/button";
import ResultsPager from "../common/pagers/resultsPager";

const DivisionReportResults = ({ divisionData, first, previous, next, last, totalRecords, recordsPerPage, currentPage, order, onExportClick }) => {

    return (
    <div aria-live="polite">
            {totalRecords > 0 &&
            <div>
                <div className="float-left">
                    <p className="text-align-center">{totalRecords} total records found.</p>
                </div>
                <div className="float-right is-print-hidden">
                    <p>
                        <Button name="btnExport" label="Export" onClick={onExportClick} className="button-link"/>
                    </p>
                </div>
            </div>
            }
        <table className="table-grid">
            <thead>
                <tr>
                    <th onClick={order} className="is-clickable">Last Name</th>
                    <th onClick={order} className="is-clickable">First Name</th>
                    <th onClick={order} className="is-clickable">Email</th>
                    <th onClick={order} className="is-clickable">Affiliation</th>
                    <th onClick={order} className="is-clickable">Affiliation Type</th>
                    <th onClick={order} className="is-clickable">Course Name</th>
                    <th onClick={order} className="is-clickable">Completion Date</th>
                </tr>
            </thead>
        <tbody>
        {divisionData.map(
            (report, index) => {
                return (<DivisionReportRow
                        key={index}
                        report={report}/>
                );
            }
        )}
        {totalRecords === 0 &&
            <tr>
                <td colSpan="7">
                No users match the search criteria.
                </td>
            </tr>
        }
        </tbody>
    {totalRecords > 0 &&
        <tfoot className={"is-print-hidden"}>
        <tr>
            <td colSpan="7">
                <ResultsPager first={first} previous={previous} next={next} last={last} totalRecords={totalRecords} recordsPerPage={recordsPerPage} currentPage={currentPage} />
            </td>
        </tr>

        </tfoot>
}
</table>
    </div>
);
};

DivisionReportResults.propTypes = {
    divisionData: PropTypes.arrayOf(PropTypes.object).isRequired,
    order: PropTypes.func,
    first: PropTypes.func.isRequired,
    last: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
    previous: PropTypes.func.isRequired,
    totalRecords: PropTypes.number.isRequired,
    currentPage: PropTypes.number,
    recordsPerPage: PropTypes.number,
    onExportClick: PropTypes.func.isRequired
};

export default DivisionReportResults;