// ------------ LAYOUT ACTIONS ------------ //
export const UPDATE_PAGETITLE = "UPDATE_PAGETITLE";
export const UPDATE_PAGE_SUBTITLE = "UPDATE_PAGE_SUBTITLE";

// ------------ AJAX ACTIONS ------------ //
export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';

// ------------ LOGIN ACTIONS ------------ //
export const LOGIN_AJAX_SUCCESS = "LOGIN_AJAX_SUCCESS";
export const EMAILCHECK_AJAX_SUCCESS = "EMAILCHECK_AJAX_SUCCESS";
export const LOGIN_SET_RETURNURL = "LOGIN_SET_RETURNURL";

// ------------ USER ACTIONS ------------- //
export const REQUEST_PASSWORD_AJAX_SUCCESS = "REQUEST_PASSWORD_AJAX_SUCCESS";
export const CREATE_PROFILE_AJAX_SUCCESS = "CREATE_PROFILE_AJAX_SUCCESS";
export const LOAD_USER_PROFILE_AJAX_SUCCESS = "LOAD_USER_PROFILE_AJAX_SUCCESS";
export const UPDATE_PROFILE_AJAX_SUCCESS = "UPDATE_PROFILE_AJAX_SUCCESS";
export const LOAD_USERS_AJAX_SUCCESS = "LOAD_USERS_AJAX_SUCCESS";
export const SAVE_SEARCH_CRITERIA = "SAVE_SEARCH_CRITERIA";

// ------------ GENERAL PROFILE DATA ACTIONS ------------- //
export const LOAD_DIVISIONS_AJAX_SUCCESS = "LOAD_DIVISIONS_AJAX_SUCCESS";
export const LOAD_ROLES_AJAX_SUCCESS = "LOAD_ROLES_AJAX_SUCCESS";
export const LOAD_COURSES_AJAX_SUCCESS = "LOAD_COURSES_AJAX_SUCCESS";
export const LOAD_DISTRICTS_AJAX_SUCCESS = "LOAD_DISTRICTS_AJAX_SUCCESS";

// ------------ REPORT ACTIONS ------------- //
export const LOAD_DIVISION_DATA_AJAX_SUCCESS = "LOAD_DIVISION_DATA_AJAX_SUCCESS";
export const SAVE_DIVISION_REPORT_SEARCH_CRITERIA = "SAVE_DIVISION_REPORT_SEARCH_CRITERIA";
export const LOAD_SUMMARY_REPORT_DATA_AJAX_SUCCESS = "LOAD_SUMMARY_REPORT_DATA_AJAX_SUCCESS";
export const SAVE_SUMMARY_REPORT_SEARCH_CRITERIA = "SAVE_SUMMARY_REPORT_SEARCH_CRITERIA";
