import {matchPath} from "react-router-dom";

function getParams(currentPath, routeToMatch, params){
    const matchedInfo = matchPath(currentPath, {
        path: routeToMatch,
        exact: true,
        strict: false
    });

    if(matchedInfo === null)
        return null;

    let routeParams = Object.keys(params);
    let paramObj = {};
    for(let param in routeParams)
        paramObj[routeParams[param]] = matchedInfo.params[routeParams[param]];

    return paramObj;
}

export {getParams};