import store from "store";
import storeExpirePlugin from "../../../tools/storeExpire";
import * as storageTypes from "./../../constants/storageTypes";
store.addPlugin(storeExpirePlugin);

export default class LocalStorage {
    static isLoggedIn() {
        const accessToken = store.get(storageTypes.ACCESS_TOKEN);
        return accessToken && accessToken.length > 0;
    }

    static isRefreshable() {
        const refreshToken = store.get(storageTypes.REFRESH_TOKEN);
        return refreshToken && refreshToken.length > 0;
    }

    static persistLogin(access_token, at_expires_in, refresh_token, rt_expires_in, isAdmin, role, divisionId, districtId) {
        store.set(storageTypes.ACCESS_TOKEN, access_token, at_expires_in);
        store.set(storageTypes.ACCESS_TOKEN_EXPIRES, at_expires_in, at_expires_in);
        store.set(storageTypes.REFRESH_TOKEN, refresh_token, rt_expires_in);
        store.set(storageTypes.REFRESH_TOKEN_EXPIRES, rt_expires_in, rt_expires_in);
        store.set(storageTypes.IS_ADMIN, isAdmin, at_expires_in);
        store.set(storageTypes.ROLE, role, at_expires_in);
        store.set(storageTypes.DISTRICT_ID, districtId, at_expires_in);
        store.set(storageTypes.DIVISION_ID, divisionId, at_expires_in);
    }

    static persistAdminRefreshToken() {
        const rt_expires_in = store.get(storageTypes.REFRESH_TOKEN_EXPIRES);
        store.set(storageTypes.ADMIN_REFRESH_TOKEN, store.get(storageTypes.REFRESH_TOKEN), rt_expires_in);
        store.set(storageTypes.ADMIN_REFRESH_TOKEN_EXPIRES, rt_expires_in, rt_expires_in);
    }

    static removeAdminRefreshToken() {
        store.remove(storageTypes.ADMIN_REFRESH_TOKEN);
        store.remove(storageTypes.ADMIN_REFRESH_TOKEN_EXPIRES);
    }

    static removeAccessToken() {
        store.remove(storageTypes.ACCESS_TOKEN);
    }

    static logout() {
        store.remove(storageTypes.ACCESS_TOKEN_EXPIRES);
        store.remove(storageTypes.ACCESS_TOKEN);
        store.remove(storageTypes.REFRESH_TOKEN);
        store.remove(storageTypes.REFRESH_TOKEN_EXPIRES);
        store.remove(storageTypes.IS_ADMIN);
        store.remove(storageTypes.ROLE);
        store.remove(storageTypes.DIVISION_ID);
        store.remove(storageTypes.DISTRICT_ID);
        store.remove(storageTypes.ADMIN_REFRESH_TOKEN);
        store.remove(storageTypes.ADMIN_REFRESH_TOKEN_EXPIRES);
    }

    static getAccessToken() {
        return store.get(storageTypes.ACCESS_TOKEN);
    }

    static getRefreshToken() {
        return store.get(storageTypes.REFRESH_TOKEN);
    }

    static getAdminRefreshToken() {
        return store.get(storageTypes.ADMIN_REFRESH_TOKEN);
    }

    static getAuthTokenValue() {
        return "Bearer " + this.getAccessToken();
    }

    static getAuthentication() {
        return {isAdmin: store.get(storageTypes.IS_ADMIN), role: store.get(storageTypes.ROLE), districtId: store.get(storageTypes.DISTRICT_ID), divisionId: store.get(storageTypes.DIVISION_ID)};
    }
}