import React from "react";
import PropTypes from "prop-types";

const CheckBoxField = ({name, label, onChange, checked, showLabel, inputClass, error, wrapperClass}) => {
    let labelClass = "is-visuallyhidden";
    if (showLabel) {
        labelClass = "";
    }

    if (error && error.length > 0) {
        wrapperClass += ' has-error';
    }

    return (
        <div className={"row row-checkbox " + wrapperClass}>
            <label htmlFor={name} className={labelClass}>
                <input type="checkbox"
                        name={name}
                        id={name}
                        className={inputClass}
                        checked={checked}
                        onChange={onChange} />
                {error && <div className="text-danger">{error}</div>}
            <span>{label}</span></label>
        </div>
    );
};

CheckBoxField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    checked: PropTypes.bool.isRequired,
    showLabel: PropTypes.bool.isRequired,
    inputClass: PropTypes.string,
    error: PropTypes.object,
    wrapperClass: PropTypes.string
};

CheckBoxField.defaultProps = {
    showLabel: true,
    checked: false,
    wrapperClass: ""
};

export default CheckBoxField;