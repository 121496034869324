import $ from 'jquery';
import * as config from "../constants/config";
import LocalStorage from "../components/shared/localStorage";
import {getAccounts,saveAccounts} from "./accountData";
import ApiUtility from "../api/apiUtility";
let delay = 0;
if(process.env.delay)
    delay = process.env.delay;

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerAccountApi {
    static checkEmail(loginViewModel) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: config.apiUrl + 'accounts/email/',
                data: {email: loginViewModel.Email},
                crossDomain: true,
                dataType: "json",
                contentType: "application/json",
                type: "GET",
                error: function (data) {
                    new ApiUtility(data, reject).errorResponseHandler();
                },
                success: function (data) {
                    new ApiUtility(data, resolve).successResponseHandler();
                }
            });
        });
    }

    static requestPasswordReset(forgotPasswordViewModel) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: config.apiUrl + 'accounts/forgot-password',
                data: JSON.stringify(forgotPasswordViewModel),
                crossDomain: true,
                dataType: "json",
                contentType: "application/json",
                type: "PUT",
                error: function (data) {
                    new ApiUtility(data, reject).errorResponseHandler();
                },
                success: function () {
                    new ApiUtility(null, resolve).successResponseHandler();
                }
            });
        });
    }

    static createProfile(accountViewModel) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: config.apiUrl + 'accounts',
                data: JSON.stringify(accountViewModel),
                crossDomain: true,
                dataType: "json",
                contentType: "application/json",
                type: "POST",
                error: function (data) {
                    new ApiUtility(data, reject).errorResponseHandler();
                },
                success: function () {
                    new ApiUtility(null, resolve).successResponseHandler();
                }
            });
        });
    }

    static getUserProfile() {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: config.apiUrl + 'accounts/current',
                beforeSend: function(request) {
                    request.setRequestHeader("Authorization", LocalStorage.getAuthTokenValue());
                },
                crossDomain: true,
                dataType: "json",
                contentType: "application/json",
                type: "GET",
                error: function (data) {
                    new ApiUtility(data, reject).errorResponseHandler();
                },
                success: function (data) {
                    new ApiUtility(data, resolve).successResponseHandler();
                }
            });
        });
    }

    static updateProfile(accountViewModel) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: config.apiUrl + 'accounts',
                data: JSON.stringify(accountViewModel),
                beforeSend: function(request) {
                    request.setRequestHeader("Authorization", LocalStorage.getAuthTokenValue());
                },
                crossDomain: true,
                dataType: "json",
                contentType: "application/json",
                type: "PUT",
                error: function (data) {
                    new ApiUtility(data, reject).errorResponseHandler();
                },
                success: function () {
                    new ApiUtility(null, resolve).successResponseHandler();
                }
            });
        });
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.

class MockAccountApi {
    static checkEmail(loginViewModel) {
        return new Promise((resolve) => {
            setTimeout(() => {
                let userAcct = getAccounts().filter(account =>
                account.Email === loginViewModel.Email);

                resolve(userAcct.length > 0);
            }, delay);
        });
    }

    static requestPasswordReset(forgotPasswordViewModel) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let userAcct = getAccounts().filter(account => account.Email === forgotPasswordViewModel.Email);

                if (userAcct.length === 0)
                    reject(
                        {
                            toastrMsg: "There was an error processing this request.",
                            errors: {
                                Email: "Email not found!"
                            }
                        });

                resolve({});
            }, delay);
        });
    }

    static getNewId() {
        return getAccounts().length + 1000;
    }

    static createProfile(accountViewModel) {
        return new Promise((resolve) => {
            setTimeout(() => {
                const newProfile = {
                    Id: this.getNewId(),
                    Email: accountViewModel.Email,
                    FirstName: accountViewModel.FirstName,
                    LastName: accountViewModel.LastName,
                    Password: accountViewModel.Password,
                    isadmin: false,
                    Role: "User",
                    RoleId: 1,
                    DivisionId: accountViewModel.DivisionId,
                    IsContractor: accountViewModel.IsContractor,
                    Courses: [
                        {
                            Acronym: "sat", CourseName: "Security Awareness Training", DateCompleted: null,
                            Sections: [
                                { SectionId: "EntireCourse", DateCompleted: null }
                            ]
                        }
                    ]
                };
                saveAccounts([...getAccounts(), newProfile]);

                resolve({});
            }, delay);
        });
    }

    static getUserProfile() {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const userAcct = getAccounts().filter(account => account.Id === parseInt(LocalStorage.getAccessToken()));
                if (userAcct.length === 0)
                    reject("There was an error while attempting to retrieve your account.");
                else
                    resolve({Id: userAcct[0].Id,
                        Email: userAcct[0].Email,
                        FirstName: userAcct[0].FirstName,
                        LastName: userAcct[0].LastName,
                        DivisionId: userAcct[0].DivisionId,
                        IsContractor: userAcct[0].IsContractor});
            }, delay);
        });
    }

    static updateProfile(accountViewModel) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                let accounts = getAccounts();
                const otherUserAccounts = accounts.filter(account => account.Id !== parseInt(LocalStorage.getAccessToken()));
                const userAcct = accounts.filter(account => account.Id === parseInt(LocalStorage.getAccessToken()));
                if (userAcct.length === 0)
                    reject("There was an error while attempting to retrieve your account.");
                else {
                    const editProfile = {
                        Id: accountViewModel.Id,
                        Email: accountViewModel.Email,
                        FirstName: accountViewModel.FirstName,
                        LastName: accountViewModel.LastName,
                        Password: (accountViewModel.UpdatePassword) ? accountViewModel.Password : userAcct[0].Password,
                        DivisionId: accountViewModel.DivisionId,
                        IsContractor: accountViewModel.IsContractor
                    };

                    accounts = [...otherUserAccounts, editProfile];
                }
                resolve({});
            }, delay);
        });
    }
}

const AccountApi = (process.env.NODE_ENV === 'production' || process.env.API_MODE === 'server') ? ServerAccountApi : MockAccountApi;
export default AccountApi;