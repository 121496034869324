import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as layoutActions from '../actions/layoutActions';

export class NotFoundPage extends React.Component {
    componentDidMount() {
        this.props.actions.updatePageTitle("Page Not Found");
    }

    render() {
        return (
            <p>Couldn't find the page, yo!</p>
        );
    }
}

NotFoundPage.propTypes = {
    actions: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(layoutActions, dispatch)
    };
}

export default connect(null, mapDispatchToProps) (NotFoundPage);