import * as actionTypes from "../constants/actionTypes";
import initialState from "./initialState";

export default function  authenticationReducer(state = initialState.authentication, action) {
    switch(action.type){
        case actionTypes.LOGIN_AJAX_SUCCESS:
            return Object.assign({}, state, { isLoggedIn: action.loginData.isLoggedIn, isAdmin: action.loginData.isAdmin, role: action.loginData.role });
        case actionTypes.LOGIN_SET_RETURNURL:
            return Object.assign({}, state, { redirectUrl: action.redirectUrl });
        default:
            return state;
    }
}