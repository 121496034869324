import React from "react";
import PropTypes from "prop-types";
import SearchUserRow from "./searchUserRow";
import ResultsPager from "../common/pagers/resultsPager";
import * as PolicyEvents from "../../constants/policyEvents";
import Allow from "../../containers/authenticate/allow";
import {checkUserPermissions} from "../authenticate/allow";

const SearchUserResults = ({ users, first, previous, next, last, totalRecords, recordsPerPage, currentPage, details, impersonate, order}) => {

    let numberOfColumns = "7";
    if(checkUserPermissions())
        numberOfColumns = "6";

    return (
        <div aria-live="polite">
            <table className="table-grid">
                <thead>
                <tr>
                    <th className={"text-align-center"}>Action</th>
                    <th onClick={order} className="is-clickable">First Name</th>
                    <th onClick={order} className="is-clickable">Last Name</th>
                    <th onClick={order} className="is-clickable">Email</th>
                    <th onClick={order} className="is-clickable">Affiliation</th>
                    <th onClick={order} className="is-clickable">Affiliation Type</th>
                    <Allow policyEvent={PolicyEvents.MANAGE}>
                        <th onClick={order} className="is-clickable">Role</th>
                    </Allow>
                </tr>
                </thead>
                <tbody>
                {users.map(
                    (user, index) => {
                        return  (<SearchUserRow
                            key={index}
                            user={user}
                            details={details}
                            impersonate={impersonate}/>);
                    }
                )}
                {totalRecords === 0 &&
                <tr>
                    <td colSpan={numberOfColumns}>
                        No users match the search criteria.
                    </td>
                </tr>
                }
                </tbody>
                {totalRecords > 0 &&
                <tfoot>
                <tr>
                    <td colSpan={numberOfColumns}>
                        <ResultsPager first={first} previous={previous} next={next} last={last} totalRecords={totalRecords} recordsPerPage={recordsPerPage} currentPage={currentPage} />
                    </td>
                </tr>

                </tfoot>
                }
            </table>
        </div>
    );
};

SearchUserResults.propTypes = {
    users: PropTypes.arrayOf(PropTypes.object).isRequired,
    order: PropTypes.func,
    first: PropTypes.func.isRequired,
    last: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
    previous: PropTypes.func.isRequired,
    totalRecords: PropTypes.number.isRequired,
    currentPage: PropTypes.number,
    recordsPerPage: PropTypes.number,
    details: PropTypes.func.isRequired,
    impersonate: PropTypes.func.isRequired
};

export default SearchUserResults;