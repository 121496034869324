import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as layoutActions from '../../actions/layoutActions';
import * as reportActions from "../../actions/reportActions";
import { Link } from "react-router-dom";
import {checkUserPermissions} from "../../components/authenticate/allow";
import localStorage from "../../components/shared/localStorage";
import * as PolicyEvents from "../../constants/policyEvents";
import * as reportConstants from "../../constants/reportConstants";
import Toastr from "toastr";
import ToastrOptions from "../../constants/toastr";
import SummaryReportSearchForm from "../../components/reports/summaryReportSearchForm";
import SummaryReportResults from "../../components/reports/summaryReportResults";
import PushFocusToElement from "../../components/common/pushFocusToElement";
import moment from "moment";
import * as config from "../../constants/config";

export class SummaryReport extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: {}
        };

        Toastr.options = ToastrOptions.Error();

        this.loadSummaryData = this.loadSummaryData.bind(this);
        this.clearCriteria = this.clearCriteria.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
    }

    componentWillMount() {
        const canViewSummaryReport = checkUserPermissions(PolicyEvents.VIEW_SUMMARY_REPORT);
        if(!canViewSummaryReport) {
            this.props.history.push(config.adminLandingPage);
        }

        this.props.actions.updatePageTitle("General Course Information Report");
        if(this.props.searchCriteria.searchPerformed) {
            this.props.actions.loadSummaryData(this.props.searchCriteria)
                .then(() => {
                    PushFocusToElement("StartDate");
                })
                .catch(error => {
                    Toastr.error(error);
                });
        }
        else
            PushFocusToElement("StartDate");
    }

    onDateChange(selectedDates, dateStr, instance) {
        const summaryReportSearchCriteria = Object.assign({}, this.props.searchCriteria);
        const searchFieldName = instance.config.class;

        dateStr = (dateStr === "") ? null : dateStr;

        summaryReportSearchCriteria[searchFieldName] = dateStr;

        this.props.actions.saveSummaryReportSearchCriteria(summaryReportSearchCriteria);
    }

    searchFormIsValid(searchCriteria, errors) {
        let formIsValid = true;

        const { StartDate, EndDate } = searchCriteria;

        if( StartDate === null ) {
            errors.StartDate = "Start date is required.";
            formIsValid = false;
        }

        if( EndDate === null ) {
            errors.EndDate = "End date is required.";
            formIsValid = false;
        }

        if(StartDate !== null && EndDate !== null && moment(StartDate).diff(moment(EndDate)) > 0) {
            errors.StartDate = "Start date needs to come before or be equal to the end date";
            formIsValid = false;
        }

        return formIsValid;
    }

    loadSummaryData(event) {
        event.preventDefault();

        let errors = {};
        let formIsValid = this.searchFormIsValid(this.props.searchCriteria, errors);

        this.setState({errors: errors});
        if (!formIsValid) {
            Toastr.error("Please correct the issues as specified.");
            return;
        }

        let searchCriteria = Object.assign({}, this.props.searchCriteria);

        searchCriteria.searchPerformed = true;

        if(!checkUserPermissions(PolicyEvents.USE_AFFILIATED_ADMIN_FILTERS)) {
            const auth = localStorage.getAuthentication();
            searchCriteria.DivisionId = auth.divisionId;
            searchCriteria.DistrictId = auth.districtId;
        }

        this.props.actions.saveSummaryReportSearchCriteria(searchCriteria);

        this.props.actions.loadSummaryData(searchCriteria)
            .catch(error => {
                Toastr.error(error);
            });
    }

    clearCriteria(event) {
        event.preventDefault();
        this.props.actions.saveSummaryReportSearchCriteria(reportConstants.generateEmptySummarySearchCriteria());
    }

    render() {
        return (
            <div>
                <p className={"is-print-hidden"}><Link to={config.adminLandingPage}>Return to Admin Menu</Link></p>
                <div className={"is-print-hidden"}>
                    <p>Specify a start and end date and click &quot;Generate Report&quot;.</p>
                </div>
                <div>
                    <hr />
                    <SummaryReportSearchForm criteria={this.props.searchCriteria}
                                             onDateChanged={this.onDateChange}
                                             clearCriteria={this.clearCriteria}
                                             generateReport={this.loadSummaryData}
                                             errors={this.state.errors}
                                             />
                    {this.props.searchCriteria.searchPerformed &&
                    <div>
                        <hr />
                        <SummaryReportResults summaryData={this.props.summaryData} />
                        <hr />
                        <p className={"is-print-hidden"}><Link to={config.adminLandingPage} className="button-link">Return to Admin Menu</Link></p>
                    </div>
                    }
                </div>
            </div>
        );
    }
}

SummaryReport.propTypes = {
    summaryData: PropTypes.object,
    searchCriteria: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    ajaxCallsLoading: PropTypes.bool
};

function mapStateToProps(state) {
    return {
        summaryData: state.summaryReport.summaryResults,
        searchCriteria: state.summaryReport.searchCriteria,
        ajaxCallsLoading: state.ajaxCallsInProgress > 0
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign({}, reportActions, layoutActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SummaryReport);