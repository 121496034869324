import * as config from "./config";

export function generateEmptyDivisionSearchCriteria() {
    return {
        RoleId: null,
        DivisionId: null,
        DistrictId: null,
        AffiliationId: null,
        EmployeeType: null,
        NameContains: "",
        NameEmailContains: "",
        Page: 1,
        RecordsPerPage: config.defaultRecordsPerPage,
        OrderByColumn: "Last Name",
        OrderDescending: false,
        searchPerformed: false
    };
}

export function generateEmptySummarySearchCriteria() {
    return {
        StartDate: null,
        EndDate: null,
        searchPerformed: false
    };
}
