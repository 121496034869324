import * as PolicyEvents from "../../constants/policyEvents";

class DefaultPolicy {
    constructor(role) {
        this._policy = {};
        this._role = role;
        this.initializePolicy();
    }

    permit(policyEvent) {
        if (this._policy[policyEvent])
            return this._policy[policyEvent](this._role);

        return false;
    }

    initializePolicy() {
        this._policy[PolicyEvents.VIEWONLY] = function (role) {
            return (role === PolicyEvents.VIEWONLY);
        };
        this._policy[PolicyEvents.MANAGE] = function (role) {
            return (role !== PolicyEvents.VIEWONLY && role !== PolicyEvents.AFFILIATED_ADMIN);
        };
        this._policy[PolicyEvents.AFFILIATED_ADMIN] = function (role) {
            return (role === PolicyEvents.AFFILIATED_ADMIN);
        };
        this._policy[PolicyEvents.VIEW_SUMMARY_REPORT] = function (role) {
            return (role !== PolicyEvents.AFFILIATED_ADMIN);
        };
        this._policy[PolicyEvents.USE_AFFILIATED_ADMIN_FILTERS] = function (role) {
            return (role !== PolicyEvents.AFFILIATED_ADMIN);
        };
    }

}

export {DefaultPolicy};