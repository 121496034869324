import React from "react";
import PropTypes from "prop-types";
import Allow from "../../containers/authenticate/allow";
import TextField from "../common/inputs/textField";
import SelectField from "../common/inputs/selectField";
import Button from "../common/buttons/button";
import ButtonLink from "../common/buttons/buttonLink";
import ButtonBar from "../common/buttons/buttonBar";
import GridColumn from "../common/gridColumn";
import GridRow from "../common/gridRow";
import {generateEmployeeTypeOptions} from "../../constants/userConstants";
import * as PolicyEvents from "../../constants/policyEvents";

const DivisionReportSearchForm = ({
                                      criteria,
                                      generalProfileData,
                                      onFormChange,
                                      clearCriteria,
                                      generateReport,
                                      errors
                                  }) => {
    const divisions = generalProfileData.divisions.map(m => {
        return {value: `Division|${m.value}`, text: m.text};
    });
    const districts = generalProfileData.districts.map(m => {
        return {value: `District|${m.value}`, text: m.text};
    });
    const affiliationList = [...divisions, ...[{
        value: "-1|-1",
        text: "------------------------- Districts -------------------------"
    }], ...districts];
    return (
        <form className={"is-print-hidden"}>
            <GridRow rowClass="filter-bar" medium="2" large="4">
                <div>
                    <GridColumn>
                        <TextField name="NameEmailContains"
                                   label="Name or Email:"
                                   onChange={onFormChange}
                                   showLabel
                                   value={criteria.NameEmailContains}
                                   maxLength={100}
                        />
                        <hr className={"spacer"}/>
                    </GridColumn>
                    <Allow policyEvent={PolicyEvents.USE_AFFILIATED_ADMIN_FILTERS}>
                        <>
                            <GridColumn>
                                <SelectField name="EmployeeType"
                                             label="Affiliation Type"
                                             onChange={onFormChange}
                                             showLabel
                                             options={generateEmployeeTypeOptions()}
                                             value={criteria.EmployeeType}
                                             defaultOption={"Any Affiliation Type"}
                                />
                            </GridColumn>
                            <GridColumn>
                                <SelectField name="AffiliationId"
                                             label="Affiliation"
                                             onChange={onFormChange}
                                             showLabel
                                             options={affiliationList}
                                             value={criteria.AffiliationId ? criteria.AffiliationId.toString() : ""}
                                             error={(errors) ? errors.AffiliationId : ""}
                                             defaultOption={"Any Affiliation"}
                                />
                            </GridColumn>
                        </>
                    </Allow>
                </div>
                <div>
                    <ButtonBar>
                        <Button name="btnGenerateReport" onClick={generateReport} label="Search"/>
                        <ButtonLink name="btnClearCriteria" onClick={clearCriteria} label="Clear"/>
                    </ButtonBar>
                </div>
            </GridRow>
        </form>
    );
};

DivisionReportSearchForm.propTypes = {
    criteria: PropTypes.object.isRequired,
    generalProfileData: PropTypes.object.isRequired,
    onFormChange: PropTypes.func.isRequired,
    clearCriteria: PropTypes.func.isRequired,
    generateReport: PropTypes.func.isRequired,
    errors: PropTypes.object
};

export default DivisionReportSearchForm;