import React from 'react';
import PropTypes from "prop-types";
import {Route} from "react-router-dom";
import {connect} from "react-redux";
import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";
import LoadGeneralProfileData from "../account/loadGeneralProfileData";
import EnsureLoggedIn from "../authenticate/ensureLoggedIn";

class DefaultLayout extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.loadPageDetails = this.loadPageDetails.bind(this);
    }

    loadPageDetails(matchProps) {
        const pageTitle = this.props.layout.pageTitle;
        const {component: Component} = this.props;
        return (
            <LoadGeneralProfileData>
                <div className="home">
                    <Header isLoading={this.props.isLoading}/>

                    <main id="main">
                        <div className="mainContent">
                            <div>
                                <h1>Information Security Awareness</h1>
                            </div>
                            <div id="content-area">
                                {pageTitle !== "" && <h2>{pageTitle}</h2>}
                                <Component {...matchProps} />
                            </div>
                        </div>

                        <Footer/>
                    </main>
                </div>
            </LoadGeneralProfileData>
        );
    }

    render() {
        const {exact, path, ensureLoggedIn} = this.props;

        if(ensureLoggedIn)
            return (
                <Route {...exact} {...path} render={matchProps => (
                    <EnsureLoggedIn>
                        {this.loadPageDetails(matchProps)}
                    </EnsureLoggedIn>
                )}/>
            );

        return (
            <Route {...exact} {...path} render={matchProps => (
                <div>
                    {this.loadPageDetails(matchProps)}
                </div>
            )}/>
        );
    }
}

DefaultLayout.defaultProps = {
    ensureLoggedIn: false
};

DefaultLayout.propTypes = {
    exact: PropTypes.bool,
    path: PropTypes.string,
    component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    ensureLoggedIn: PropTypes.bool,
    isLoading: PropTypes.bool.isRequired,
    layout: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        layout: state.layout,
        isLoading: state.ajaxCallsInProgress > 0
    };
}

export default connect(mapStateToProps)(DefaultLayout);