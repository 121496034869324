import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import {withRouter} from "react-router-dom";
import Toastr from "toastr";
import ToastrOptions from "../../constants/toastr";

import * as accountActions from "../../actions/accountActions";
import * as authenticationActions from "../../actions/authenticationActions";
import * as layoutActions from "../../actions/layoutActions";

import * as config from "../../constants/config";
import {CheckboxType, RadioType, SelectListType, adjustDelimitedList} from "../../components/common/htmlUtilities";

import CreateProfile from '../../components/account/profile';
import ProfileFormIsValid from "../../components/account/userValidation";
import PushFocusToElement from "../../components/common/pushFocusToElement";
import {createSelectListObject} from "../../components/shared/selectListHelpers";

let recaptchaId;

export class CreateProfilePage extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            accountViewModel: {
                FirstName: "",
                LastName: "",
                Email: "",
                Password: "",
                VerifyPassword: "",
                DivisionId: null,
                DistrictId: null,
                EmployeeType: null,
                IsContractor: null,
                IsDoeEmployee: null,
                IsDistrictEmployee: null,
                recaptchaResponse: ""
            },
            generalProfileData: {
                divisions: [],
                districts: []
            },
            isLoading: false,
            errors: {}
        };

        Toastr.options = ToastrOptions.Error();

        this.onCreateClick = this.onCreateClick.bind(this);
        this.onCancelClick = this.onCancelClick.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
    }

    componentWillMount() {
        if(this.props.account.Email === "")
            this.props.history.push("/");

        const accountViewModel = this.state.accountViewModel;
        accountViewModel.Email = this.props.account.Email;
        this.setState({accountViewModel: accountViewModel});

        this.props.actions.updatePageTitle("New User");

        // just in case they already exist and need to be put into the state
        this.updateGeneralProfileData(this.props);

        this.addRecaptchaObject();
    }

    componentDidMount() {
        this.addRecaptchaObject();
    }

    componentWillReceiveProps(newProps) {
        this.updateGeneralProfileData(newProps);
    }

    addRecaptchaObject() {
        if( document.getElementById("recaptchav2")) {
            recaptchaId = window.grecaptcha.render('recaptchav2', {
                'sitekey': process.recapthca.key
            });
        }
    }

    updateGeneralProfileData(newProps) {
        let allLoaded = true;
        ["divisions"].map( generalDataName => {
            if (this.state.generalProfileData[generalDataName].length === 0 &&
                newProps.generalProfileData[generalDataName].length > 0) {
                const selectListItems = newProps.generalProfileData[generalDataName].map(
                    item => createSelectListObject(generalDataName, item)
                );

                const generalProfileData = this.state.generalProfileData;
                generalProfileData[generalDataName] = selectListItems;
                this.setState({generalProfileData: generalProfileData});
            }
            else if(this.state.generalProfileData[generalDataName].length === 0)
                allLoaded = false;
        });

        ["districts"].map( generalDataName => {
            if (this.state.generalProfileData[generalDataName].length === 0 &&
                newProps.generalProfileData[generalDataName].length > 0) {
                const selectListItems = newProps.generalProfileData[generalDataName].map(
                    item => createSelectListObject(generalDataName, item)
                );

                const generalProfileData = this.state.generalProfileData;
                generalProfileData[generalDataName] = selectListItems;
                this.setState({generalProfileData: generalProfileData});
            }
            else if(this.state.generalProfileData[generalDataName].length === 0)
                allLoaded = false;
        });

        if(allLoaded)
            PushFocusToElement("FirstName");
    }

    onFormChange(e) {
        const accountViewModel = this.state.accountViewModel;
        const name = e.target.name;
        if(e.target.type === CheckboxType) {
            if(name === "UpdatePassword")
                accountViewModel[name] = e.target.checked;
            else {
                const checkboxInfo = name.split("_");
                accountViewModel[checkboxInfo[0]] = adjustDelimitedList(accountViewModel[checkboxInfo[0]], checkboxInfo[1], e.target.checked);
            }
        }
        else if(e.target.type === RadioType || e.target.type === SelectListType)
            accountViewModel[name] = (e.target.value == "") ? null : parseInt(e.target.value);

            if(name === "EmployeeType"){
                accountViewModel.IsDoeEmployee = null;
                accountViewModel.IsContractor = null;
                accountViewModel.IsDistrictEmployee = null;

                switch(accountViewModel[name]) {
                    case 1:
                        accountViewModel.IsDoeEmployee = 1;
                        accountViewModel.DistrictId =  null;
                        break;
                    case 2:
                        accountViewModel.IsContractor = 1;
                        accountViewModel.DistrictId =  null;
                        break;
                    case 3:
                        accountViewModel.IsDistrictEmployee = 1;
                        accountViewModel.DivisionId =  null;
                        break;

                }
            }
        else
            accountViewModel[name] = e.target.value;

        this.setState({ accountViewModel: accountViewModel });
    }

    getRecaptchaResponse() {
        const accountViewModel = this.state.accountViewModel;
        accountViewModel.recaptchaResponse = window.grecaptcha.getResponse(recaptchaId);
        this.setState({ accountViewModel: accountViewModel });
    }

    onCreateClick(e) {
        e.preventDefault();

        let errors = {};
        let formIsValid = ProfileFormIsValid(this.state.accountViewModel, errors);
        this.setState({errors: errors});
        if (!formIsValid) {
            Toastr.error("Please correct the issues as specified.");
            return;
        }

        this.setState({ isLoading: true });
        this.getRecaptchaResponse();
        this.props.actions.createProfile(this.state.accountViewModel)
            .then(() => {
                const loginViewModel = {
                    Email: this.state.accountViewModel.Email,
                    Password: this.state.accountViewModel.Password
                };
                this.props.actions.attemptLogin(loginViewModel)
                    .then(() => this.redirect())
                    .catch(error => {
                        if(error !== "")
                            Toastr.error(error);

                        this.setState({isLoading: false});
                    });
            })
            .catch(error => {
                if( error.errors )
                    this.setState({errors: error.errors});
                if(error.toastrMsg && error.toastrMsg !== "")
                    Toastr.error(error.toastrMsg);
                this.setState({isLoading: false});
            });
    }

    redirect() {
        this.setState({isLoading: false});
        const {isLoggedIn, redirectUrl} = this.props.authentication;
        if(isLoggedIn) {
            let redirectStr = (redirectUrl !== "") ? redirectUrl : config.courseLandingPage;
            if (process.env.NODE_ENV === 'production')
                window.location.href = redirectStr;
            else
                this.props.history.push(redirectStr);
        }
        else
            this.props.history.push("/");
    }

    onCancelClick(e) {
        e.preventDefault();

        const userViewModel = this.state.accountViewModel;
        userViewModel.Email = "";
        userViewModel.Password = "";
        this.setState({ accountViewModel: userViewModel });

        this.props.actions.checkEmailSuccess(false);

        this.redirect();
    }

    render() {
        return (
            <CreateProfile
                isCreate={true}
                accountViewModel={this.state.accountViewModel}
                generalProfileData={this.state.generalProfileData}
                pageDirections="Create a profile to access the course as your email<br/>address has not been registered with our system.<br/><br/><span class='text-danger'>If you have trouble with the accessibility on the<br/>reCaptcha service, please use Firefox or Chrome."
                onClick={this.onCreateClick}
                onCancel={this.onCancelClick}
                onChange={this.onFormChange}
                isLoading={this.state.isLoading}
                ajaxCallsLoading={this.props.ajaxCallsLoading}
                errors={this.state.errors}
            />
        );
}
}

CreateProfilePage.propTypes = {
    loginViewModel: PropTypes.object,
    actions: PropTypes.object.isRequired,
    account: PropTypes.object,
    authentication: PropTypes.object,
    generalProfileData: PropTypes.object,
    ajaxCallsLoading: PropTypes.bool,
    history: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        account: state.account,
        authentication: state.authentication,
        generalProfileData: state.generalProfileData,
        ajaxCallsLoading: state.ajaxCallsInProgress > 0
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        accountActions,
        authenticationActions,
        layoutActions
    );
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (CreateProfilePage));
