import React from 'react';
import PropTypes from "prop-types";
import Loader from "../common/loader";
import Logo from "../../images/DOE_logo.png";

const Header = ({isLoading}) => {
    return (
        <div>
            <Loader isLoading={isLoading} />
            <header>
                {<img src={Logo} alt="Information Security Awareness" className="home-logo" />}
            </header>
        </div>
    );
};


Header.propTypes = {
    isLoading: PropTypes.bool.isRequired
};

Header.defaultProps = {
    pageTitle: "",
    subTitle: "",
    isLoading: false
};

export default Header;