import React from 'react';
import PropTypes from "prop-types";
import TextField from '../common/inputs/textField';
import Button from '../common/buttons/button';

const EmailCheckForm = ({ loginViewModel, onClick, onChange, isLoading, errors }) => {

    return (
        <div>
            <p>Enter your email to access or register for the course.</p>

            <p><strong>Please use the Firefox, Chrome or Edge browsers.<br/>Internet Explorer may not work with this website.</strong></p>

            <form className="one-col-condensed-form">
                <TextField
                    name="Email"
                    label="Email: "
                    value={loginViewModel.Email}
                    onChange={onChange}
                    inputClass="input-login"
                    showLabel
                    error={(errors) ? errors.Email: ""}
                    maxLength={300}
                />

                <div className="row buttonContainer">
                    <Button
                        name="btnCheckEmail"
                        label="Next"
                        onClick={onClick}
                        btnClass="input-green"
                        disabled={isLoading}
                    />
                </div>
            </form>
        </div>
    );
};

EmailCheckForm.propTypes = {
    loginViewModel: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    errors: PropTypes.object
};

export default EmailCheckForm;
