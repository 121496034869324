import React from 'react';
import PropTypes from "prop-types";
import Button from '../common/buttons/button';
import ButtonLink from '../common/buttons/buttonLink';
import SelectField from '../common/inputs/selectField';
import UserForm from "../account/userForm";
import CourseInfo from "./courseInfo";

const EditUser = ({ accountViewModel, generalProfileData, onClick, onCancel, onChange, onDateChange, isLoading, ajaxCallsLoading, errors }) => {

    let formEnabledClass = "two-col-form";
    let fieldSetEnabled = "";
    if(ajaxCallsLoading) {
        formEnabledClass += " disable-form";
        fieldSetEnabled = "disabled";
    }
    return (
        <div>
            <p>Update the profile and click <strong>Update</strong> below.</p>

            <span className="text-required-legend text-danger">* are required fields</span>

            <ButtonLink name="btnCancelTop" onClick={onCancel} label="Cancel" />

            <hr />
            <hr className="spacer" />

            <form className={formEnabledClass}>
                <fieldset disabled={fieldSetEnabled}>

                    <div className="row">
                        <strong>Profile Information</strong>
                    </div>

                    <SelectField
                            name="RoleId"
                            label="Role:"
                            onChange={onChange}
                            error={(errors) ? errors.RoleId : ""}
                            showLabel
                            value={(accountViewModel.RoleId) ? accountViewModel.RoleId.toString() : ""}
                            options={generalProfileData.roles} />

                    <UserForm
                        isCreate={false}
                        accountViewModel={accountViewModel}
                        generalProfileData={generalProfileData}
                        onChange={onChange}
                        errors={errors} />

                    <CourseInfo
                        accountViewModel={accountViewModel}
                        generalProfileData={generalProfileData}
                        onDateChange={onDateChange}
                        errors={errors} />

                    <div className="row">
                        <hr className="spacer" />
                    </div>
                    <ButtonLink
                        name="btnCancelBottom"
                        onClick={onCancel}
                        label="Cancel" />

                    <Button
                        name="btnUser"
                        label="Update"
                        onClick={onClick}
                        btnClass="input-green"
                        disabled={isLoading}
                    />
                </fieldset>
            </form>
        </div>
    );
};

EditUser.propTypes = {
    accountViewModel: PropTypes.object.isRequired,
    generalProfileData: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onDateChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    ajaxCallsLoading: PropTypes.bool.isRequired,
    errors: PropTypes.object
};

export default EditUser;
