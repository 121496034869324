import React from 'react';
import PropTypes from "prop-types";
import {isString} from "../commonUtilities";
import HtmlDisplay from "../HtmlDisplay";

class DisplayField extends React.Component {
    constructor(props){
        super(props);
    }

    render() {
        let {children,
            inputClass,
            label,
            labelClass,
            name,
            showLabel,
            wrapperClass
        } = this.props;

        const isHtml = isString(children);

        if (!showLabel) {
            labelClass += " is-visuallyhidden";
        }

        return (
            <div className={"row " + wrapperClass}>
                <label className={"columns column-1 " + labelClass} htmlFor={name}>{label}</label>
                <div className={"columns column-2 " + inputClass}>
                    {
                        isHtml &&
                        <HtmlDisplay
                            name={name}
                            html={children}
                        />
                    }
                    {
                        !isHtml &&
                        <div id={name}>{children}</div>
                    }
                </div>
            </div>
        );
    }
}

DisplayField.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.bool,
        PropTypes.object,
        PropTypes.string
    ]).isRequired,
    inputClass: PropTypes.string,
    label: PropTypes.string.isRequired,
    labelClass: PropTypes.string,
    name: PropTypes.string.isRequired,
    showLabel: PropTypes.bool,
    wrapperClass: PropTypes.string
};

DisplayField.defaultProps = {
    wrapperClass: "",
    labelClass: "small-12",
    inputClass: "small-12"
};

export default DisplayField;