import React from 'react';
import PropTypes from "prop-types";
import TextField from '../common/inputs/textField';
import CheckBox from '../common/inputs/checkBoxField';

const EditFields = ({ accountViewModel, onChange, errors }) => {

    const disabledPassword = (!accountViewModel.UpdatePassword);
    return (
        <span>
            <TextField
                name="Email"
                label="Email:"
                value={accountViewModel.Email}
                onChange={onChange}
                showLabel
                error={(errors) ? errors.Email : ""}
                maxLength={300}
                isRequired
            />

            <CheckBox
                name="UpdatePassword"
                label="Update your password"
                onChange={onChange}
                checked={accountViewModel.UpdatePassword}
                showLabel
                wrapperClass="column-2"
                 />

            <TextField
                inputType="password"
                name="Password"
                label="Password:"
                value={accountViewModel.Password}
                onChange={onChange}
                inputClass="input-password"
                showLabel
                disabled={disabledPassword}
                error={(errors) ? errors.Password : ""}
                maxLength={50}
                isRequired
            />

            <TextField
                inputType="password"
                name="VerifyPassword"
                label="Verify Password:"
                value={accountViewModel.VerifyPassword}
                onChange={onChange}
                inputClass="input-password"
                showLabel
                disabled={disabledPassword}
                error={(errors) ? errors.VerifyPassword : ""}
                maxLength={50}
                isRequired
            />
        </span>
    );
};

EditFields.propTypes = {
    accountViewModel: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object
};

export default EditFields;
