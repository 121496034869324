import React from 'react';
import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import { setRedirectUrl, loginSuccess, refreshLogin} from '../../actions/authenticationActions';
import { connect } from 'react-redux';
import localStorage from "../../components/shared/localStorage";

export class EnsureLoggedIn extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { dispatch, redirectUrl, isLoggedIn } = this.props;

        if (!localStorage.isLoggedIn()) {
            if(localStorage.isRefreshable()) {
                dispatch(refreshLogin())
                    .then(() => window.location.reload());
            }
            else {
                dispatch(setRedirectUrl(redirectUrl));
                this.props.history.replace("/");
            }
        }
        else if(!isLoggedIn) {
            const auth = localStorage.getAuthentication();
            dispatch(loginSuccess(true, auth.isAdmin, auth.role));
        }
    }

    render() {
        if (this.props.isLoggedIn && localStorage.isLoggedIn()) {
            return this.props.children;
        } else {
            return null;
        }
    }
}

EnsureLoggedIn.propTypes = {
    dispatch: PropTypes.func,
    redirectUrl: PropTypes.string,
    children: PropTypes.object,
    isLoggedIn: PropTypes.bool,
    history: PropTypes.object
};

function mapStateToProps(state, ownProps) {
    return {
        isLoggedIn: state.authentication.isLoggedIn,
        redirectUrl: ownProps.location.pathname
    };
}
export default withRouter(connect(mapStateToProps)(EnsureLoggedIn));
