import React from "react";
import PropTypes from "prop-types";
import createFragment from 'react-addons-create-fragment';

const KnowledgeCheckItemAnalysis = ({ itemResponses, headerResponses}) => {

    if(itemResponses === null || itemResponses === undefined || itemResponses.length === 0)
        return null;

    return (
        <div>
            <table className="table-item-analysis">
                <thead>
                <tr>
                    <th rowSpan="2">Item</th>
                    <th rowSpan="2">Total Responses</th>
                    {headerResponses.map(
                        (response, index) => {
                            return (<th colSpan="2" key={index}>{response}</th>);
                        }
                    )}
                </tr>
                <tr>
                    {headerResponses.map(
                        () => {
                            return (
                                createFragment({
                                    c1: <th>Total</th>,
                                    c2: <th>Percent</th>
                                })
                            );
                        }
                    )}
                </tr>
                </thead>
                <tbody>
                { itemResponses.map(
                    (itemResponse, index) => {
                        return(
                            <tr key={index}>
                                <td>{itemResponse.ItemId}</td>
                                <td>{itemResponse.TotalResponses}</td>
                                {itemResponse.Responses.map(
                                    (response) => {
                                        return (
                                            createFragment({
                                                c1: <td>{response.Total}</td>,
                                                c2: <td>{response.Percent}</td>
                                            })
                                        );
                                    }
                                )}
                            </tr>
                        );
                    }
                )}
                </tbody>
            </table>
        </div>
    );
};

KnowledgeCheckItemAnalysis.propTypes = {
    itemResponses: PropTypes.arrayOf(PropTypes.object),
    headerResponses: PropTypes.arrayOf(PropTypes.string)
};

export default KnowledgeCheckItemAnalysis;