import React from "react";
import PropTypes from "prop-types";

const GridColumn = ({children, columnClass, large, medium, small, align}) => {
    return (
        <div className={columnClass + " columns" + " large-" + large + " medium-" + medium + " small-" + small + " align-self-" + align}>
            {children}
        </div>
    );
};

GridColumn.propTypes = {
    children: PropTypes.any,//eslint-disable-line react/forbid-prop-types
    columnClass: PropTypes.string,
    large: PropTypes.string,
    medium: PropTypes.string,
    small: PropTypes.string,
    align: PropTypes.string
};

GridColumn.defaultProps = {
    columnClass: "",
    large: "",
    medium: "",
    small: "12",
    align: ""
};

export default GridColumn;