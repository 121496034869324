import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {DefaultPolicy} from "./DefaultPolicy";
import * as PolicyEvents from "../../constants/policyEvents";

export class Allow extends React.Component {
    render() {
        const {children}  = this.props;
        let {policy, policyEvent} = this.props;

        policy = policy ? new policy : new DefaultPolicy(this.props.authentication.role);

        if (policy.permit(policyEvent))
            return children;
        else
            return null;
    }
}

Allow.propTypes = {
    children: PropTypes.object.isRequired,
    policy: PropTypes.func,
    policyEvent: PropTypes.string,
    authentication: PropTypes.object
};

Allow.defaultProps = {
    policyEvent: PolicyEvents.VIEWONLY
};

function mapStateToProps(state) {
    return {
        authentication: state.authentication
    };
}


export default connect(mapStateToProps)(Allow);