export const ACCESS_TOKEN = "SAT_FCIM_AT";
export const ACCESS_TOKEN_EXPIRES = "SAT_FCIM_ATE";
export const REFRESH_TOKEN = "SAT_FCIM_RT";
export const REFRESH_TOKEN_EXPIRES = "SAT_FCIM_RTE";
export const ADMIN_REFRESH_TOKEN = "SAT_FCIM_ART";
export const ADMIN_REFRESH_TOKEN_EXPIRES = "SAT_FCIM_ARTE";
export const IS_ADMIN = "IS_ADMIN";
export const ROLE = "ROLE";
export const DISTRICT_ID = "DISTRICT_ID";
export const DIVISION_ID = "DIVISION_ID";

