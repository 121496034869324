import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as layoutActions from '../../actions/layoutActions';
import {Link} from "react-router-dom";

export class NotAuthorized extends React.Component {
    componentDidMount() {
        this.props.actions.updatePageTitle("Not Authorized");
    }

    render() {
        return (
            <div>
                <p>Not Authorized!</p>
                <p><Link to="/">Logout</Link></p>
            </div>
        );
    }
}

NotAuthorized.propTypes = {
    actions: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(layoutActions, dispatch)
    };
}

export default connect(null, mapDispatchToProps) (NotAuthorized);