import React from 'react';
import PropTypes from "prop-types";
import TextField from '../common/inputs/textField';

const CreateField = ({ accountViewModel, onChange, errors }) => {
    return (
        <span>
            <div className="row">
                    <div className="columns non-text-field-padding">
                        <p>This password may not be the same as your FDOE password<br/>
                           if you recently updated your FDOE password. You are NOT<br/>
                           required to change this password however, it is recommended<br/>
                           that you update it. Once you log in to the course, select<br/>
                           “Edit Profile” then “Update Password”.</p>
                    </div>
            </div>

            <div className="row">
                <span className="columns column-1">Email: </span>
                <div className="columns column-2 non-text-field-padding text-align-left">
                    <strong>{accountViewModel.Email}</strong>
                    {
                        errors.Email && <div className="text-danger">{errors.Email}</div>
                    }
                </div>
            </div>

            <TextField
                inputType="password"
                name="Password"
                label="Password:"
                value={accountViewModel.Password}
                onChange={onChange}
                inputClass="input-password"
                showLabel
                error={(errors) ? errors.Password : ""}
                maxLength={50}
                isRequired
            />

            <TextField
                inputType="password"
                name="VerifyPassword"
                label="Verify Password:"
                value={accountViewModel.VerifyPassword}
                onChange={onChange}
                inputClass="input-password"
                showLabel
                error={(errors) ? errors.VerifyPassword : ""}
                maxLength={50}
                isRequired
            />
        </span>
    );
};

CreateField.propTypes = {
    accountViewModel: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object
};

export default CreateField;
