export default {
    // more options - http://codeseven.github.io/toastr/demo.html
    _error: {
        "closeButton": true,
        "debug": false,
        "newestOnTop": true,
        "progressBar": true,
        "positionClass": "toast-middle-center",
        "hideDuration": "500",
        "timeOut": 0,
        "extendedTimeOut": 0,
    },
    _success: {
        "closeButton": true,
        "newestOnTop": true,
        "progressBar": true,
        "positionClass": "toast-top-center",
        "hideDuration": "1000"
    },
    _info: {
        "closeButton": true,
        "newestOnTop": true,
        "progressBar": true,
        "positionClass": "toast-top-center",
        "hideDuration": "1000"
    },
    _warning: {
        "closeButton": true,
        "newestOnTop": true,
        "progressBar": true,
        "positionClass": "toast-top-center",
        "hideDuration": "1000"
    },
    Error: function () {
        return this._error;
    },
    Success: function () {
        return this._success;
    },
    Info: function () {
        return this._info;
    },
    Warning: function () {
        return this._warning;
    }
};
