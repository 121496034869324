import React from "react";
import PropTypes from "prop-types";
import Button from "./button";

//LINK BUTTON
const ButtonLink = ({ name, label, onClick, disabled }) => {
    return (
        <Button btnType="button" label={label} name={name} onClick={onClick} btnClass="button-link" disabled={disabled}><span />{label}</Button>
    );
};

ButtonLink.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool
};

ButtonLink.defaultProps =  {
    disabled: false
};

export default ButtonLink;