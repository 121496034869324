import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import NotAuthorized from './NotAuthorized';
import storage from "../../components/shared/localStorage";

function Authorization(WrappedComponent) {
    class WithAuthorization extends React.Component {
        render() {
            const isImpersonating = !!storage.getAdminRefreshToken();
            if (this.props.authentication.isAdmin || isImpersonating) {
                return <WrappedComponent {...this.props} />;
            } else {
                return <NotAuthorized />;
            }
        }
    }

    WithAuthorization.propTypes = {
        authentication: PropTypes.object
    };

    function mapStateToProps(state) {
        return {
            authentication: state.authentication
        };
    }

    return connect(mapStateToProps) (WithAuthorization);
}

export {Authorization};