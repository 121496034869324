import generalProfileDataAction from "../api/generalProfileDataApi";
import { beginAjaxCall, ajaxCallError } from "./ajaxStatusActions";
import * as actionTypes from "../constants/actionTypes";
import * as config from "../constants/config";
import {refreshLogin} from "./authenticationActions";

export function loadDivisionsSuccess(allDivisions) {
    return { type: actionTypes.LOAD_DIVISIONS_AJAX_SUCCESS, allDivisions };
}

export function loadDivisions() {
    return dispatch => {
        dispatch(beginAjaxCall());
        return generalProfileDataAction.getDivisions()
            .then((allDivisions) => {
                dispatch(loadDivisionsSuccess(allDivisions));
            })
            .catch(error => {
                if(config.useRefreshToken === error) {
                    dispatch(refreshLogin())
                        .then(() => {
                            dispatch(loadDivisions());
                        });
                }
                else {
                    dispatch(ajaxCallError(error));
                    throw (error);
                }
            });
    };
}

export function loadRolesSuccess(allRoles) {
    return { type: actionTypes.LOAD_ROLES_AJAX_SUCCESS, allRoles };
}

export function loadRoles() {
    return dispatch => {
        dispatch(beginAjaxCall());
        return generalProfileDataAction.getRoles()
            .then((allRoles) => {
                dispatch(loadRolesSuccess(allRoles));
            })
            .catch(error => {
                if(config.useRefreshToken === error) {
                    dispatch(refreshLogin())
                        .then(() => {
                            dispatch(loadRoles());
                        });
                }
                else {
                    dispatch(ajaxCallError(error));
                    throw (error);
                }
            });
    };
}

export function loadCoursesSuccess(allCourses) {
    return { type: actionTypes.LOAD_COURSES_AJAX_SUCCESS, allCourses };
}

export function loadCourses() {
    return dispatch => {
        dispatch(beginAjaxCall());
        return generalProfileDataAction.getCourses()
            .then((allCourses) => {
                dispatch(loadCoursesSuccess(allCourses));
            })
            .catch(error => {
                if(config.useRefreshToken === error) {
                    dispatch(refreshLogin())
                        .then(() => {
                            dispatch(loadCourses());
                        });
                }
                else {
                    dispatch(ajaxCallError(error));
                    throw (error);
                }
            });
    };
}

export function loadDistrictsSuccess(allDistricts) {
    return { type: actionTypes.LOAD_DISTRICTS_AJAX_SUCCESS, allDistricts };
}

export function loadDistricts() {
    return dispatch => {
        dispatch(beginAjaxCall());
        return generalProfileDataAction.getDistricts()
            .then((allDistricts) => {
                dispatch(loadDistrictsSuccess(allDistricts));
            })
            .catch(error => {
                if(config.useRefreshToken === error) {
                    dispatch(refreshLogin())
                        .then(() => {
                            dispatch(loadDistricts());
                        });
                }
                else {
                    dispatch(ajaxCallError(error));
                    throw (error);
                }
            });
    };
}