import * as actionTypes from "../constants/actionTypes";
import initialState from "./initialState";

export default function  generalProfileDataReducer(state = initialState.generalProfileData, action) {
    switch(action.type){
        case actionTypes.LOAD_DIVISIONS_AJAX_SUCCESS:
            return Object.assign({}, state, { divisions: action.allDivisions });
        case actionTypes.LOAD_ROLES_AJAX_SUCCESS:
            return Object.assign({}, state, { roles: action.allRoles });
        case actionTypes.LOAD_COURSES_AJAX_SUCCESS:
            return Object.assign({}, state, { courses: action.allCourses });
        case actionTypes.LOAD_DISTRICTS_AJAX_SUCCESS:
            return Object.assign({}, state, { districts: action.allDistricts });
        default:
            return state;
    }
}