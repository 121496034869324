import React from "react";
import PropTypes from "prop-types";
import DateTimePicker from "../flatpickr-node/react-flatpickr";
import '../flatpickr-node/flatpickr/dist/themes/light.css';
// https://chmln.github.io/flatpickr/options/
// put node module local in common folder so that I could modify to include tab as a keypress - if ((e.key === "Enter" || e.key === "Tab") && isInput) {
// AND self.close(); before return statement

// In order to allow DateTimePicker, using flatpickr.js, to support Form labeling for accessibility the name/id has been added to flatpickr.js with the following two line additions after line 1878:
//     self.altInput.name = self.config.class;
//     self.altInput.id = self.config.class;



const DateTimePickerField = ({   name,
                             label,
                             onChange,
                             value = '',
                             showLabel,
                             error,
                             wrapperClass,
                             labelClass,
                             inputClass,
                             disabled,
                             isRequired,
                             dateFormat,
                             enableTime
                         }) => {

    if (!showLabel) labelClass += " is-visuallyhidden";
    wrapperClass += disabled ? " is-disabled" : "";

    if (error && error.length > 0) {
        wrapperClass += " has-error";
    }

    const options = {
        altFormat: dateFormat,
        allowInput: true,
        altInput: true,
        enableTime: enableTime,
        onChange: onChange,
        class: name
    };

    return (
        <div className={"row " + wrapperClass}>
            <label className={"columns column-1 " + labelClass} htmlFor={name}>{ (isRequired) && <span className="text-danger">* </span>}{label}</label>
            <div className={"columns column-2 " + inputClass}>
                <DateTimePicker
                    value={value}
                    options={options}
                />
            </div>

            {error && <div className="text-danger">{error}</div>}
        </div>

    );
};

DateTimePickerField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.string,
    showLabel: PropTypes.bool,
    error: PropTypes.string,
    disabled: PropTypes.bool,

    wrapperClass: PropTypes.string,
    labelClass: PropTypes.string,
    inputClass: PropTypes.string,
    isRequired: PropTypes.bool,
    dateFormat: PropTypes.string,
    enableTime: PropTypes.bool
};


DateTimePickerField.defaultProps = {
    wrapperClass: "",
    disabled: false,
    labelClass: "small-12",
    inputClass: "small-12",
    isRequired: false,
    dateFormat: "m-j-Y h:iK",
    enableTime: true
};

export default DateTimePickerField;