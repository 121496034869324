import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

import ForgotPasswordForm from '../../components/account/forgotPasswordForm';
import Toastr from "toastr";
import ToastrOptions from "../../constants/toastr";
import * as accountActions from "../../actions/accountActions";
import * as layoutActions from "../../actions/layoutActions";

export class ForgotPasswordPage extends React.Component{
    constructor(props) {
        super(props);

        this.state = {
            forgotPasswordViewModel: {
                Email: ""
            },
            isLoading: false,
            errors: {}
        };

        Toastr.options = ToastrOptions.Error();

        this.onFormChange = this.onFormChange.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    componentDidMount(){
        this.props.actions.updatePageTitle("Forgot Password");
    }

    onFormChange(e) {
        const forgotPasswordViewModel = this.state.forgotPasswordViewModel;

        const name = e.target.name;
        const value = e.target.value;

        forgotPasswordViewModel[name] = value;

        this.setState({ forgotPasswordViewModel: forgotPasswordViewModel });
    }

    forgotPasswordFormIsValid(){
        let formIsValid = true;
        let errors = {};
        let emailPattern = /(.+)@(.+){2,}\.(.+){2,}/;

        const {Email} = this.state.forgotPasswordViewModel;

        if (Email.trim().length === 0){
            errors.Email = 'Email is required';
            formIsValid = false;
        }
        else{
            if (!emailPattern.test(Email)){
                errors.Email = 'Email is invalid';
                formIsValid = false;
            }
        }

        this.setState({errors: errors});
        return formIsValid;
    }

    emailSentConfirmation(){
        Toastr.success("An email has been sent to " + this.state.forgotPasswordViewModel.Email);
        this.setState({ forgotPasswordViewModel : { Email: "" }});
    }

    onClick(e) {
        e.preventDefault();

        if (!this.forgotPasswordFormIsValid()){
            Toastr.error("Please correct the issues as specified and try again.");
            return;
        }

        this.props.actions.requestPasswordReset(this.state.forgotPasswordViewModel)
            .then(() => this.emailSentConfirmation())
            .catch(error => {
                if( error.errors )
                    this.setState({errors: error.errors});

                if(error.toastrMsg && error.toastrMsg !== "")
                    Toastr.error(error.toastrMsg);
                else if (error)
                    Toastr.error(error)

                this.setState({isLoading: false});
            });
    }

    render(){
        return (
            <ForgotPasswordForm
                forgotPasswordViewModel={this.state.forgotPasswordViewModel}
                onClick={this.onClick}
                onChange={this.onFormChange}
                isLoading={this.state.isLoading}
                errors={this.state.errors}
            />
        );
    }
}

ForgotPasswordPage.propTypes = {
    actions: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch){
    const combinedActions = Object.assign(
        {},
        accountActions,
        layoutActions);

    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default connect(null, mapDispatchToProps) (ForgotPasswordPage);
