import React from "react";
import PropTypes from "prop-types";
import * as PolicyEvents from "../../constants/policyEvents";
import Allow from "../../containers/authenticate/allow";
import KnowledgeCheckItemAnalysis from "./knowledgeCheckItemAnalysis";

const SummaryReportResults = ({ summaryData}) => {
    if(summaryData.ProfileDataSummaries === undefined)
        return null;

    return (
        <div className={"report-area"}>
            <p><strong>Date Range {summaryData.DateRange } (Inclusive)</strong></p>

            <p><strong>Overall Information</strong></p>
            <p>Total # of users registered (based upon the user's creation date): {summaryData.TotalUsersRegistered } <br/>
            Total # of users that accessed the course (based upon a section being completed or any slide being viewed): {summaryData.TotalUsersAccessed } </p>

            <Allow policyEvent={PolicyEvents.USE_AFFILIATED_ADMIN_FILTERS}>
                <>
                    <p><strong>Profile Information Totals (all based upon the user's creation date)</strong></p>
                    {summaryData.ProfileDataSummaries.map(
                        (profile, index) => {
                            return (
                              <blockquote key={index}>
                                  <p><strong>{profile.Question}</strong></p>
                                  <ul>
                                  {Object.keys(profile.QuestionOptionsWithTotals).map(
                                      (property, index) => {
                                          if(!profile.QuestionOptionsWithTotals.hasOwnProperty(property))
                                              return;

                                          return (
                                              <li key={index}>{property} - {profile.QuestionOptionsWithTotals[property]}</li>
                                          );
                                      }
                                  )}
                                  </ul>
                              </blockquote>
                            );
                        }
                    )}
                </>
            </Allow>

            <p><strong>Course Information</strong></p>
            {summaryData.CourseDataSummaries.map(
                (course, index) => {
                    return  (
                        <blockquote key={index}>
                            <p><strong>{course.CourseName}</strong></p>
                            <p>Total # of users enrolled (based upon the enrollment creation date): {course.GeneralTotalSummaryInfo.TotalUsersEnrolled}<br/>
                            Total # of completers (based upon the enrollment completion date): {course.GeneralTotalSummaryInfo.TotalUsersCompleted}</p>

                            <p><strong>Knowledge Checks (all based upon completion date)</strong></p>
                            {course.KnowledgeCheckTotalSummaries.map(
                                (knowledgeCheck, index) => {
                                    return (
                                        <blockquote key={index}>
                                            <p><strong>Slide: {knowledgeCheck.SectionId}</strong></p>
                                            <p>Average # of attempts: {knowledgeCheck.AverageNumberOfAttempts}<br/>
                                            Average passing score: {knowledgeCheck.AveragePassingScore}<br/>
                                            Total # of attempts: {knowledgeCheck.TotalNumberOfAttempts}<br/>
                                            Total # of passed: {knowledgeCheck.TotalNumberPassed}</p>

                                            <KnowledgeCheckItemAnalysis itemResponses={knowledgeCheck.ItemResponses} headerResponses={knowledgeCheck.HeaderResponses} />
                                        </blockquote>
                                    );
                                }
                            )}
                        </blockquote>
                    );
                }
            )}
        </div>
    );
};

SummaryReportResults.propTypes = {
    summaryData: PropTypes.object.isRequired
};

export default SummaryReportResults;