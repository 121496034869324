import React from 'react';
import PropTypes from "prop-types";
import ButtonLink from '../common/buttons/buttonLink';
import ViewUserForm from "../account/viewUserForm";
import ViewCourseInfo from "../admin/viewCourseInfo";

const ViewUser = ({ accountViewModel, generalProfileData, onCancel }) => {

    return (
        <div>
            <span className="text-required-legend">&nbsp;</span>

            <ButtonLink name="btnCancelTop" onClick={onCancel} label="Cancel" />

            <hr />
            <hr className="spacer" />


            <div className="row">
                <strong>Profile Information</strong>
            </div>

            <div className="two-col-view">
                <ViewUserForm
                    accountViewModel={accountViewModel}
                    generalProfileData={generalProfileData} />

                <ViewCourseInfo
                    accountViewModel={accountViewModel}
                    generalProfileData={generalProfileData}
                     />

                <div className="row">
                    <hr className="spacer" />
                </div>
                <ButtonLink
                    name="btnCancelBottom"
                    onClick={onCancel}
                    label="Cancel" />
            </div>
        </div>
    );
};

ViewUser.propTypes = {
    accountViewModel: PropTypes.object.isRequired,
    generalProfileData: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired
};

export default ViewUser;
