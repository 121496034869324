import React from 'react';
import PropTypes from 'prop-types';
import {toDangerouslySetHtml} from "./htmlUtilities";

const HtmlDisplay = ({
                         name,
                         html,
                         isInline
                     }) => {
    if(isInline) {
        return (
            <span
                id={name}
                dangerouslySetInnerHTML={toDangerouslySetHtml(html)}//eslint-disable-line react/no-danger
            />
        );
    }

    return (
        <div
            id={name}
            dangerouslySetInnerHTML={toDangerouslySetHtml(html)}//eslint-disable-line react/no-danger
        />
    );
};

HtmlDisplay.propTypes = {
    html: PropTypes.string.isRequired,
    name: PropTypes.string,
    isInline: PropTypes.bool
};

HtmlDisplay.defaultProps = {
    isInline: false,
    html: ""
};

export default HtmlDisplay;