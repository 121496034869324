import React from 'react';
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import TextField from '../common/inputs/textField';
import Button from '../common/buttons/button';

const ForgotPasswordForm = ({ forgotPasswordViewModel, onClick, onChange, isLoading, errors }) => {
    return (
        <div>
            <p>Enter your email address into the box below and you will<br/>
                receive an email with a link to update your password.</p>

            <form className="one-col-condensed-form">
                <TextField
                    name="Email"
                    label="Email: "
                    value={forgotPasswordViewModel.Email}
                    onChange={onChange}
                    inputClass="input-login"
                    showLabel
                    error={(errors) ? errors.Email: ""}
                    maxLength={300}
                />


                <div className="row text-align-right">
                    <Link className="button-link text-align-center" to="/">Cancel</Link>

                    <Button
                        name="btnSubmit"
                        label="Send reminder"
                        onClick={onClick}
                        btnClass="input-green"
                        disabled={isLoading}
                    />
                </div>
            </form>
        </div>
    );
};

ForgotPasswordForm.propTypes = {
    forgotPasswordViewModel: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    errors: PropTypes.object
};

export default ForgotPasswordForm;
