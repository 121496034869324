import React from "react";
import PropTypes from "prop-types";
import * as PolicyEvents from "../../constants/policyEvents";
import Allow from "../../containers/authenticate/allow";
import ButtonIcon from "../common/buttons/buttonIcon";

const SearchUserRow = ({ user, details, impersonate }) => {
    return (
        <tr className="highlight">
            <td className={"text-align-center no-wrap"} id={user.Id}>
                <ButtonIcon name={"btnEdit"} label={"Edit"} onClick={details} iconType={"pencil"} />
                <ButtonIcon name={"btnImpersonate"} label={"Impersonate"} onClick={impersonate} iconType={"impersonate"} />
            </td>
            <td>
                {user.FirstName}
            </td>
            <td>
                {user.LastName}
            </td>
            <td>
                {user.Email}
            </td>
            <td>
                {user.Division}
                {user.District}
            </td>
            <td>
                {user.EmployeeTypeOutput}
            </td>
            <Allow policyEvent={PolicyEvents.MANAGE}>
                <td>
                    {user.Role}
                </td>
            </Allow>
        </tr>
    );
};

SearchUserRow.propTypes = {
    user: PropTypes.object.isRequired,
    details: PropTypes.func.isRequired,
    impersonate: PropTypes.func.isRequired
};

export default SearchUserRow;