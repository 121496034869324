import React from 'react';
import PropTypes from "prop-types";

const ButtonMock = ({ name, label, btnClass }) => {
    return (
        <span id={name} className={"button-mock "  + btnClass}>{label}</span>
    );
};

ButtonMock.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string.isRequired,
    btnClass: PropTypes.string
};

ButtonMock.defaultProps = {
    btnClass: "",
    disabled: false
};

export default ButtonMock;
