import React from "react";
import PropTypes from "prop-types";

const ButtonIcon = ({ name, label, onClick, iconType, disabled }) => {
    return (
        <button id={name} onClick={onClick} className={"icons icon-" + iconType} disabled={disabled} aria-label={label}><span /></button>
    );
};

ButtonIcon.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    iconType: PropTypes.string.isRequired,
    disabled: PropTypes.bool
};

ButtonIcon.defaultProps =  {
    disabled: false
};

export default ButtonIcon;
