import React from "react";
import PropTypes from "prop-types";

const SelectField = ({   name,
                         label,
                         showLabel,
                         onChange,
                         includeDefaultOption,
                         defaultOption,
                         value,
                         error,
                         options,
                         wrapperClass,
                         labelClass,
                         inputClass,
                         isRequired
                    }) => {

    if (!showLabel) {
        labelClass += " is-visuallyhidden";
    }

    if (error && error.length > 0) {
        wrapperClass += " has-error";
    }

    let selectListOptions = [];
    if(options && options.length > 0) {
        selectListOptions = options;
    }

    if(value === null){
        value = "";
    }
    return (
        <div className={"row " + wrapperClass}>
            <label htmlFor={name} className={"column-1 " + labelClass}>{ (isRequired) && <span className="text-danger">* </span>}{label}</label>
            <div className={"columns column-2 " + inputClass}>
                <select name={name}
                       id={name}
                       value={value}
                       onChange={onChange}>
                    {includeDefaultOption && <option value="">{defaultOption}</option>}
                    {selectListOptions.map((listItem, index) =>
                        {
                            return <option key={index} value={listItem.value}>{listItem.text}</option>;
                        })
                    }
                </select>
            </div>
            {error && <div className="text-danger">{error}</div>}
        </div>
    );
};

SelectField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    defaultOption: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.string,
    showLabel: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.object),
    includeDefaultOption: PropTypes.bool,

    wrapperClass: PropTypes.string,
    labelClass: PropTypes.string,
    inputClass: PropTypes.string,
    isRequired: PropTypes.bool
};

SelectField.defaultProps = {
    includeDefaultOption: true,
    defaultOption: "Select an Option",
    wrapperClass: "",
    labelClass: "small-12",
    inputClass: "small-12",
    isRequired: false
};

export default SelectField;