import React from 'react';
import PropTypes from "prop-types";
import moment from "moment";
import ViewDetails from "../common/viewDetails";

const ViewCourseInfo = ({ accountViewModel, generalProfileData }) => {
    return (
        <div>
            {generalProfileData.courses.map((course, i) => {
                let overallDate = "";
                if(accountViewModel[course.Acronym + "CompletionDate"] !== null)
                    overallDate = moment(accountViewModel[course.Acronym + "CompletionDate"]).format("M-D-YYYY h:mma");
                let allSectionsComplete = true;

                return (
                    <div key={i}>
                        <div className="row">
                            <hr className="spacer" />
                            <strong>{course.CourseName} Information</strong>
                        </div>

                        {
                            course.SectionIds.map((sectionId, sI) => {
                                let selectedDate = null;
                                if(accountViewModel[sectionId + "CompletionDate"] !== null)
                                    selectedDate = moment(accountViewModel[sectionId + "CompletionDate"]).format("M-D-YYYY h:mma");

                                if(selectedDate === null)
                                    allSectionsComplete = false;

                                return (
                                    <ViewDetails
                                        key={sI}
                                        label={sectionId + ":"}
                                        value={selectedDate}
                                    />
                                );
                            })
                        }

                        {
                            allSectionsComplete &&
                            <ViewDetails
                                label="Overall:"
                                value={overallDate}
                            />
                        }

                    </div>
                );
            })}
        </div>
    );
};

ViewCourseInfo.propTypes = {
    accountViewModel: PropTypes.object.isRequired,
    generalProfileData: PropTypes.object.isRequired
};

export default ViewCourseInfo;
