import React from "react";
import PropTypes from "prop-types";

const GridRow = ({children, rowClass, large, medium, small, align}) => {
    return (
        <div className={rowClass + " row" + " large-up-" + large + " medium-up-" + medium + " small-up-" + small + " align-" + align}>
            {children}
        </div>
    );
};

GridRow.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.arrayOf(PropTypes.object)
    ]),
    rowClass: PropTypes.string,
    large: PropTypes.string,
    medium: PropTypes.string,
    small: PropTypes.string,
    align: PropTypes.string
};

GridRow.defaultProps = {
    rowClass: "",
    large: "",
    medium: "",
    small: "",
    align: ""
};

export default GridRow;