import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import {withRouter} from "react-router-dom";
import Toastr from "toastr";
import ToastrOptions from "../../constants/toastr";
import * as accountActions from "../../actions/accountActions";
import * as authenticationActions from "../../actions/authenticationActions";
import * as layoutActions from "../../actions/layoutActions";
import * as config from "../../constants/config";
import {createRedirectUrl} from "../../components/common/htmlUtilities";

import LoginForm from '../../components/authenticate/loginForm';
import EmailCheckForm from '../../components/authenticate/emailCheckForm';
import PushFocusToElement from '../../components/common/pushFocusToElement';

export class LoginPage extends React.Component {
    constructor(props, context) {
        super(props, context);

        this.state = {
            loginViewModel: {
                Email: "",
                Password: ""
            },
            emailExists: false,
            isLoading: false,
            errors: {}
        };

        Toastr.options = ToastrOptions.Error();

        this.onEmailCheckClick = this.onEmailCheckClick.bind(this);
        this.onLoginClick = this.onLoginClick.bind(this);
        this.onLoginCancelClick = this.onLoginCancelClick.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
    }

    componentWillMount() {
        const redirectUrl = createRedirectUrl(this.props.location.search, this.props.location.hash);
        if(redirectUrl !== "")
            this.props.actions.setRedirectUrl(redirectUrl);

        this.props.actions.updatePageTitle("");
        this.props.actions.logOff();
    }

    componentDidMount() {
        PushFocusToElement("Email");
    }

    componentWillReceiveProps(nextProps){
        if(this.state.emailExists !== nextProps.account.emailExists)
        {
            this.setState({emailExists: nextProps.account.emailExists});
        }
    }

    onFormChange(e) {
        const loginViewModel = this.state.loginViewModel;
        const name = e.target.name;

        loginViewModel[name] = e.target.value;

        this.setState({ loginViewModel: loginViewModel });
    }

    emailCheckFormIsValid() {
        let formIsValid = true;
        let errors = {};

        const { Email } = this.state.loginViewModel;
        if (Email.trim().length === 0) {
            errors.Email = 'Email is required.';
            formIsValid = false;
        }

        this.setState({errors: errors});
        return formIsValid;
    }

    onEmailCheckClick(e) {
        e.preventDefault();

        if (!this.emailCheckFormIsValid()) {
            Toastr.error("Please correct the issues as specified and try logging in again.");
            return;
        }

        this.setState({ isLoading: true });
        this.props.actions.checkEmail(this.state.loginViewModel)
            .then(() => this.verifyEmailCheck())
            .catch(error => {
                if(error !== "")
                    Toastr.error(error);
                this.setState({isLoading: false});
            });
    }

    verifyEmailCheck() {
        this.setState({isLoading: false});
        if(!this.state.emailExists) {
            this.redirect();
        }
        else {
            PushFocusToElement("Password");
        }
    }

    loginFormIsValid() {
        let formIsValid = true;
        let errors = {};

        const { Password } = this.state.loginViewModel;

        if (Password.trim().length === 0) {
            errors.Password = 'Password is required.';
            formIsValid = false;
        }

        this.setState({errors: errors});
        return formIsValid;
    }

    onLoginClick(e) {
        e.preventDefault();

        if (!this.loginFormIsValid()) {
            Toastr.error("Please correct the issues as specified and try logging in again.");
            return;
        }

        this.setState({ isLoading: true });
        this.props.actions.attemptLogin(this.state.loginViewModel)
            .then(() => this.redirect())
            .catch(error => {
                if(error !== "")
                    Toastr.error(error);
                this.setState({isLoading: false});
            });
    }

    redirect() {
        this.setState({isLoading: false});
        const {isLoggedIn, redirectUrl, isAdmin} = this.props.authentication;
        if(isLoggedIn) {
            if(isAdmin) {
                if (redirectUrl !== "")
                    window.location.href = redirectUrl;
                else
                    this.props.history.push(config.adminLandingPage);
            }
            else {
                let redirectStr = (redirectUrl !== "") ? redirectUrl : config.courseLandingPage;
                if (process.env.NODE_ENV === 'production')
                    window.location.href = redirectStr;
                else
                    this.props.history.push(redirectStr);
            }
        }
        else
            this.props.history.push("CreateProfile");
    }

    onLoginCancelClick(e) {
        e.preventDefault();

        const loginViewModel = this.state.loginViewModel;
        loginViewModel.Email = "";
        loginViewModel.Password = "";
        this.setState({ loginViewModel: loginViewModel });

        this.props.actions.checkEmailSuccess(false);
        setTimeout(() => PushFocusToElement("Email"));
    }

    render() {
        if (this.state.loginViewModel.Email !== "" && this.state.emailExists) {
            return (
                <LoginForm
                    loginViewModel={this.state.loginViewModel}
                    onClick={this.onLoginClick}
                    onCancel={this.onLoginCancelClick}
                    onChange={this.onFormChange}
                    isLoading={this.state.isLoading}
                    errors={this.state.errors}
                />
            );
        }
        else {
            return(
                <EmailCheckForm
                    loginViewModel={this.state.loginViewModel}
                    onClick={this.onEmailCheckClick}
                    onChange={this.onFormChange}
                    isLoading={this.state.isLoading}
                    errors={this.state.errors}
                />
            );
        }
    }
}

LoginPage.propTypes = {
    loginViewModel: PropTypes.object,
    actions: PropTypes.object.isRequired,
    account: PropTypes.object,
    authentication: PropTypes.object,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        account: state.account,
        authentication: state.authentication
    };
}

function mapDispatchToProps(dispatch) {
    const combinedActions = Object.assign(
        {},
        accountActions,
        authenticationActions,
        layoutActions);
    return {
        actions: bindActionCreators(combinedActions, dispatch)
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps) (LoginPage));
