import React from 'react';
import PropTypes from "prop-types";
import DateTimePickerField from "../common/inputs/DateTimePickerField";
import moment from "moment";

const CourseInfo = ({ accountViewModel, generalProfileData, onDateChange, errors }) => {
    return (
        <div>
            {generalProfileData.courses.map((course, i) => {
                let overallDate = null;
                if(accountViewModel[course.Acronym + "CompletionDate"] !== null)
                    overallDate = moment(accountViewModel[course.Acronym + "CompletionDate"]).format("YYYY-MM-DD HH:mm");
                let allSectionsComplete = true;

                return (
                    <div key={i}>
                        <div className="row">
                            <hr className="spacer" />
                            <strong>{course.CourseName} Information</strong>
                            <p>Select a date and time of completion for a section.  If you manually change the date, make sure you hit enter afterwards.</p>
                        </div>

                        {
                            course.SectionIds.map((sectionId, sI) => {
                                let selectedDate = null;
                                if(accountViewModel[sectionId + "CompletionDate"] !== null)
                                    selectedDate = moment(accountViewModel[sectionId + "CompletionDate"]).format("YYYY-MM-DD HH:mm");
                                if(selectedDate === null)
                                    allSectionsComplete = false;

                                return (
                                    <DateTimePickerField
                                        key={sI}
                                        name={course.Acronym + ";" + sectionId}
                                        label={sectionId}
                                        error={(errors) ? errors[sectionId + "CompletionDate"] : ""}
                                        onChange={onDateChange}
                                        value={selectedDate}
                                        showLabel
                                    />
                                );
                            })
                        }

                        {
                            allSectionsComplete &&
                            <DateTimePickerField
                                key={i}
                                name={course.Acronym + ";" + course.Acronym}
                                label={"Overall"}
                                error={(errors) ? errors[course.Acronym + "CompletionDate"] : ""}
                                onChange={onDateChange}
                                value={overallDate}
                                showLabel
                                isRequired
                            />
                        }

                    </div>
                );
            })}
        </div>
    );
};

CourseInfo.propTypes = {
    accountViewModel: PropTypes.object.isRequired,
    generalProfileData: PropTypes.object.isRequired,
    onDateChange: PropTypes.func.isRequired,
    errors: PropTypes.object
};

export default CourseInfo;
