import $ from 'jquery';
import * as config from "../constants/config";
import ApiUtility from "./apiUtility";
let delay = 0;
if(process.env.delay)
    delay = process.env.delay;

//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerGeneralProfileDataApi {
    static getDivisions() {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: config.apiUrl + 'divisions',
                crossDomain: true,
                dataType: "json",
                contentType: "application/json",
                type: "GET",
                error: function (data) {
                    new ApiUtility(data, reject).errorResponseHandler();
                },
                success: function (data) {
                    new ApiUtility(data, resolve).successResponseHandler();
                }
            });
        });
    }

    static getRoles() {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: config.apiUrl + 'roles',
                crossDomain: true,
                dataType: "json",
                contentType: "application/json",
                type: "GET",
                error: function (data) {
                    new ApiUtility(data, reject).errorResponseHandler();
                },
                success: function (data) {
                    new ApiUtility(data, resolve).successResponseHandler();
                }
            });
        });
    }

    static getCourses() {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: config.apiUrl + 'courses',
                crossDomain: true,
                dataType: "json",
                contentType: "application/json",
                type: "GET",
                error: function (data) {
                    new ApiUtility(data, reject).errorResponseHandler();
                },
                success: function (data) {
                    new ApiUtility(data, resolve).successResponseHandler();
                }
            });
        });
    }

    static getDistricts() {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: config.apiUrl + 'districts',
                crossDomain: true,
                dataType: "json",
                contentType: "application/json",
                type: "GET",
                error: function (data) {
                    new ApiUtility(data, reject).errorResponseHandler();
                },
                success: function (data) {
                    new ApiUtility(data, resolve).successResponseHandler();
                }
            });
        });
    }
}


//************************************************** MOCK API SERVER CALLS ****************************************

// This file mocks a web API by working with the hard-coded data below.
// It uses setTimeout to simulate the delay of an AJAX call.
// All calls return promises.
let generalProfileData = {
    roles: [
        { RoleId: 1, RoleName: "User" },
        { RoleId: 3, RoleName: "Admin" },
        { RoleId: 4, RoleName: "View Only Admin" }
    ],
    courses: [
        { Acronym: "sat", CourseName: "Security Awareness Training", SectionIds: ["EntireCourse"] }
    ],
    divisions: [
        { DivisionId: 1, DivisionName: "A Division"},
        { DivisionId: 2, DivisionName: "B Division"},
        { DivisionId: 3, DivisionName: "C Division"},
        { DivisionId: 4, DivisionName: "D Division"}
    ],
    districts: [
        { DistrictId: 1, DistrictName: "Alachua"},
        { DistrictId: 2, DistrictName: "Baker"},
        { DistrictId: 3, DistrictName: "Bay"},
        { DistrictId: 4, DistrictName: "Bradford"}
    ]
};

class MockGeneralProfileDataApi {
    static getDivisions() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(generalProfileData.divisions);
            }, delay);
        });
    }

    static getDistricts() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(generalProfileData.districts);
            }, delay);
        });
    }

    static getRoles() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(generalProfileData.roles);
            }, delay);
        });
    }

    static getCourses() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(generalProfileData.courses);
            }, delay);
        });
    }
}

const GeneralProfileDataApi = (process.env.NODE_ENV === 'production' || process.env.API_MODE === 'server') ? ServerGeneralProfileDataApi : MockGeneralProfileDataApi;
export default GeneralProfileDataApi;