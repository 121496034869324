import React from "react";
import PropTypes from "prop-types";
import ButtonBar from "../buttons/buttonBar";
import ButtonIcon from "../buttons/buttonIcon";
import ButtonMock from "../buttons/buttonMock";
import * as iconTypes from "../../../constants/buttonIconTypes";

const ResultsPager = ({first, previous, next, last, totalRecords, recordsPerPage, currentPage}) => {

    let firstVisibleRecord = (currentPage - 1) * recordsPerPage + 1;

    let recordsOnCurrentPage = currentPage * recordsPerPage;
    let lastVisibleRecord = totalRecords > recordsOnCurrentPage ?
        ((currentPage - 1) * recordsPerPage) + recordsPerPage :
        totalRecords;

    let canNavigateBackward = currentPage === 1;
    let canNavigateForward = lastVisibleRecord >= totalRecords;

    return (
        <ButtonBar>
            <ButtonIcon onClick={first} iconType={iconTypes.FirstPage} name="btnFirstPage" label="First Page" disabled={canNavigateBackward} />
            <ButtonIcon onClick={previous} iconType={iconTypes.PreviousPage} name="btnPreviousPage" label="Previous Page" disabled={canNavigateBackward} />

            <ButtonMock name="record-information" label={firstVisibleRecord + " - " + lastVisibleRecord + " of " + totalRecords} />

            <ButtonIcon onClick={next} iconType={iconTypes.NextPage} name="btnNextPage" label="Next Page" disabled={canNavigateForward} />
            <ButtonIcon onClick={last} iconType={iconTypes.LastPage} name="btnLastPage" label="Last Page" disabled={canNavigateForward} />

        </ButtonBar>
    );
};

ResultsPager.propTypes = {
    first: PropTypes.func.isRequired,
    previous:PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
    last: PropTypes.func.isRequired,
    totalRecords: PropTypes.number,
    recordsPerPage: PropTypes.number,
    currentPage: PropTypes.number
};

export default ResultsPager;