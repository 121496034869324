import accountApi from "../api/accountApi";
import { beginAjaxCall, ajaxCallError } from "./ajaxStatusActions";
import * as actionTypes from "../constants/actionTypes";
import storage from "../components/shared/localStorage";
import * as config from "../constants/config";
import {refreshLogin} from "./authenticationActions";

export function checkEmailSuccess(emailInfo) {
    return { type: actionTypes.EMAILCHECK_AJAX_SUCCESS, emailInfo };
}

export function checkEmail(loginViewModel) {
    return dispatch => {
        dispatch(beginAjaxCall());
        return accountApi.checkEmail(loginViewModel)
            .then((emailCheckResults) => {
                const emailInfo = { emailCheckResults, Email: loginViewModel.Email };
                dispatch(checkEmailSuccess(emailInfo));
            })
            .catch(error => {
                storage.logout();
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function requestPasswordReset(forgotPasswordViewModel) {
    return dispatch => {
        dispatch(beginAjaxCall());

        return accountApi.requestPasswordReset(forgotPasswordViewModel)
            .then(() => {
                dispatch(requestPasswordResetSuccess());
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function requestPasswordResetSuccess() {
    return { type: actionTypes.REQUEST_PASSWORD_AJAX_SUCCESS };
}

export function createProfileSuccess(userInfo) {
    return { type: actionTypes.CREATE_PROFILE_AJAX_SUCCESS, userInfo };
}

export function createProfile(accountViewModel) {
    return dispatch => {
        dispatch(beginAjaxCall());
        return accountApi.createProfile(accountViewModel)
            .then(() => {
                dispatch(createProfileSuccess(accountViewModel));
            })
            .catch(error => {
                dispatch(ajaxCallError(error));
                throw (error);
            });
    };
}

export function loadProfileSuccess(userInfo) {
    return { type: actionTypes.LOAD_USER_PROFILE_AJAX_SUCCESS, userInfo };
}

export function loadProfile() {
    return dispatch => {
        dispatch(beginAjaxCall());
        return accountApi.getUserProfile()
            .then((userInfo) => {
                dispatch(loadProfileSuccess(userInfo));
            })
            .catch(error => {
                if(config.useRefreshToken === error) {
                    dispatch(refreshLogin())
                        .then(() => {
                            dispatch(loadProfile());
                        });
                }
                else {
                    dispatch(ajaxCallError(error));
                    throw (error);
                }
            });
    };
}

export function updateProfileSuccess(userInfo) {
    return { type: actionTypes.UPDATE_PROFILE_AJAX_SUCCESS, userInfo };
}

export function updateProfile(accountViewModel) {
    return dispatch => {
        dispatch(beginAjaxCall());
        return accountApi.updateProfile(accountViewModel)
            .then(() => {
                dispatch(updateProfileSuccess(accountViewModel));
            })
            .catch(error => {
                if(config.useRefreshToken === error) {
                    dispatch(refreshLogin())
                        .then(() => {
                            dispatch(updateProfile(accountViewModel));
                        });
                }
                else {
                    dispatch(ajaxCallError(error));
                    throw (error);
                }
            });
    };
}


