import React from "react";
import PropTypes from "prop-types";

const ButtonBar = ({children, buttonBarPosition, name }) => {
    if (children === undefined || children === null || children.length === 0) buttonBarPosition=" is-hidden";

    return (
        <div className={"button-bar " + buttonBarPosition} name={name} id={name}>
            {children}
        </div>
    );
};

ButtonBar.propTypes = {
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
    buttonBarPosition: PropTypes.string,
    name: PropTypes.string
};
ButtonBar.defaultProps = {
    buttonBarPosition: ""
};

export default ButtonBar;