import React from 'react';
import PropTypes from "prop-types";
import * as PolicyEvents from "../../constants/policyEvents";
import {checkUserPermissions} from "../authenticate/allow";
import {optionTextInOptionsArray} from "../common/commonUtilities";
import DisplayField from "../common/inputs/displayField";
import TextField from '../common/inputs/textField';
import SelectField from '../common/inputs/selectField';
import CreateField from './createFields';
import EditField from './editFields';
import RadioField from "../common/inputs/radioField";
import {generateEmployeeTypeOptions} from "../../constants/userConstants";

const UserForm = ({ isCreate, accountViewModel, generalProfileData, onChange, errors }) => {

    const isAffiliatedAdmin = checkUserPermissions(PolicyEvents.AFFILIATED_ADMIN);

    return (
        <div>
            <TextField
                name="FirstName"
                label="First Name:"
                value={accountViewModel.FirstName}
                onChange={onChange}
                showLabel
                isRequired
                error={(errors) ? errors.FirstName: ""}
                maxLength={50}
            />

            <TextField
                name="LastName"
                label="Last Name:"
                value={accountViewModel.LastName}
                onChange={onChange}
                showLabel
                isRequired
                error={(errors) ? errors.LastName: ""}
                maxLength={50}
            />

            {
                isCreate && <CreateField accountViewModel={accountViewModel} onChange={onChange} errors={errors} />
            }
            {
                !isCreate && <EditField accountViewModel={accountViewModel} onChange={onChange}  errors={errors} />
            }

            {
                !isAffiliatedAdmin &&
                <>
                    <RadioField
                        name={"EmployeeType"}
                        label={"Choose your affiliation: "}
                        error={(errors) ? errors.EmployeeType: ""}
                        showLabel
                        isRequired
                        value={(accountViewModel.EmployeeType === null || accountViewModel.EmployeeType === undefined) ? "" : accountViewModel.EmployeeType.toString()}
                        onChange={onChange} options={generateEmployeeTypeOptions()}/>

                    {
                        (accountViewModel.EmployeeType === 1 || accountViewModel.EmployeeType === 2) &&
                        <SelectField
                            name = "DivisionId"
                            label = "Affiliated Division:"
                            isRequired = {true}
                            onChange = {onChange}
                            error = {(errors) ? errors.DivisionId : ""}
                            showLabel
                            value = {(accountViewModel.DivisionId) ? accountViewModel.DivisionId.toString() : ""}
                            options = {generalProfileData.divisions}
                        />
                    }


                    {
                        (accountViewModel.EmployeeType === 3) &&
                        <SelectField
                            name = "DistrictId"
                            label = "Affiliated District:"
                            isRequired = {true}
                            onChange = {onChange}
                            error = {(errors) ? errors.DistrictId : ""}
                            showLabel
                            value = {(accountViewModel.DistrictId) ? accountViewModel.DistrictId.toString() : ""}
                            options = {generalProfileData.districts}
                        />
                    }
                </>
            }
            {
                isAffiliatedAdmin &&
                <>
                    <DisplayField
                        inputClass={"small-12 display-value"}
                        label={"Affiliation:"}
                        name={"EmployeeType"}
                        showLabel={true}>
                        {optionTextInOptionsArray(generateEmployeeTypeOptions(), accountViewModel.EmployeeType)}
                    </DisplayField>
                    {
                        (accountViewModel.EmployeeType === 1 || accountViewModel.EmployeeType === 2) &&
                        <DisplayField
                            inputClass={"small-12 display-value"}
                            label={"Affiliated Division:"}
                            name={"DivisionId"}
                            showLabel={true}>
                            {optionTextInOptionsArray(generalProfileData.divisions, accountViewModel.DivisionId)}
                        </DisplayField>
                    }
                    {
                        (accountViewModel.EmployeeType === 3) &&
                        <DisplayField
                            inputClass={"small-12 display-value"}
                            label={"Affiliated District:"}
                            name={"DistrictId"}
                            showLabel={true}>
                            {optionTextInOptionsArray(generalProfileData.districts, accountViewModel.DistrictId)}
                        </DisplayField>
                    }
                </>
            }
    </div>
    );
};

UserForm.propTypes = {
    isCreate: PropTypes.bool.isRequired,
    accountViewModel: PropTypes.object.isRequired,
    generalProfileData: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object
};

export default UserForm;
