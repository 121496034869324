import React from "react";
import * as config from "../../constants/config";

const Footer = () => {
    return (
        <footer className="home-footer">
            <div className="home-footer-body">
                <p>For Technical Support: (800) 357-1072 or<br/>
                <a href={"mailto:" + config.supportEmail}>{config.supportEmail}</a><br/>
                &copy; 2018 Florida Department of Education
                </p>
            </div>
        </footer>
    );
};

export default Footer;