import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Route} from "react-router-dom";
import Footer from "../../components/layout/footer";
import Header from "../../components/layout/header";
import LoadGeneralProfileData from "../account/loadGeneralProfileData";
import EnsureLoggedIn from "../authenticate/ensureLoggedIn";

class FullWideLayout extends React.Component {
    constructor(props, context) {
        super(props, context);
    }

    render() {
        const pageTitle = this.props.layout.pageTitle;
        const subTitle = this.props.layout.subTitle;
        const {component: Component, exact, path} = this.props;

        return (
            <Route {...exact} {...path} render={matchProps => (
                <EnsureLoggedIn>
                    <LoadGeneralProfileData>
                        <div className="theme-fullWide">
                            <div className="l-page-container l-flex-container">

                                <Header pageTitle={pageTitle} subTitle={subTitle} isLoading={this.props.isLoading}/>

                                <div className={"courseTitle"}>
                                    <h1>Information Security Awareness</h1>
                                </div>

                                <main className="callout main-container">
                                    <div className="callout-body">
                                        <div>
                                            <h1>{pageTitle}</h1>
                                        </div>
                                        <Component {...matchProps} />
                                    </div>
                                </main>

                                <Footer/>

                            </div>
                        </div>
                    </LoadGeneralProfileData>
                </EnsureLoggedIn>
            )}/>
        );
    }
}

FullWideLayout.propTypes = {
    exact: PropTypes.bool,
    path: PropTypes.string,
    component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    isLoading: PropTypes.bool.isRequired,
    layout: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        layout: state.layout,
        isLoading: state.ajaxCallsInProgress > 0
    };
}

export default connect(mapStateToProps)(FullWideLayout);