import "babel-polyfill";

import React from "react";
import { render } from "react-dom";
import configureStore, { history } from "./store/configureStore";
import { Provider } from "react-redux";
import { ConnectedRouter } from 'connected-react-router'
import App from "./components/App";

const store = configureStore();

render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <App />
        </ConnectedRouter>
    </Provider>,
    document.getElementById("app")
);