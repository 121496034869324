import * as actionTypes from "../constants/actionTypes";
import initialState from "./initialState";

export default function divisionReportReducer(state = initialState.divisionReport, action) {
    switch(action.type){
        case actionTypes.LOAD_DIVISION_DATA_AJAX_SUCCESS:
            return Object.assign(
                {},
                state,
                {
                    searchResults: [...action.data.Data],
                    totalRecords: action.data.TotalRecords
                });
        case actionTypes.SAVE_DIVISION_REPORT_SEARCH_CRITERIA:
            return Object.assign({}, state, { searchCriteria: action.criteria});
        default:
            return state;
    }
}