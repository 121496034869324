import React from 'react';
import PropTypes from "prop-types";

class TextField extends React.Component {
    constructor(props){
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onInput = this.onInput.bind(this);
    }

    onChange(event) {
        this.props.onChange(event);
    }

    onInput(event) {
        this.onChange = null;
        this.props.onChange(event);
    }

    render() {
        let {inputType,
             name,
             label,
             placeholder,
             value = '',
             showLabel,
             error,
             wrapperClass,
             labelClass,
             inputClass,
             disabled,
             maxLength,
             isRequired
         } = this.props;

        if (!showLabel) {
            labelClass += " is-visuallyhidden";
        }

        if (error && error.length > 0) {
            wrapperClass += " has-error";
        }

        return (
            <div className={"row " + wrapperClass}>
                <label className={"columns column-1 " + labelClass} htmlFor={name}>{ (isRequired) && <span className="text-danger">* </span>}{label}</label>
                <div className={"columns column-2 " + inputClass}>
                    <input type={inputType ? inputType : "text"}
                           name={name}
                           id={name}
                           placeholder={placeholder}
                           value={value}
                           disabled={disabled}
                           onInput={this.onInput}
                           onChange={this.onChange}
                           maxLength={maxLength}
                    />
                </div>

                {error && <div className="text-danger">{error}</div>}
            </div>
        );
    }
}

TextField.propTypes = {
    inputType: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    showLabel: PropTypes.bool,
    error: PropTypes.string,

    wrapperClass: PropTypes.string,
    labelClass: PropTypes.string,
    inputClass: PropTypes.string,
    disabled: PropTypes.bool,
    maxLength: PropTypes.number.isRequired,
    isRequired: PropTypes.bool
};

TextField.defaultProps = {
    wrapperClass: "",
    disabled: false,
    labelClass: "small-12",
    inputClass: "small-12",
    isRequired: false
};

export default TextField;