import React from "react";
import PropTypes from "prop-types";
import DateTimePickerField from "../common/inputs/DateTimePickerField";
import Button from "../common/buttons/button";
import ButtonLink from "../common/buttons/buttonLink";
import ButtonBar from "../common/buttons/buttonBar";
import GridColumn from "../common/gridColumn";
import GridRow from "../common/gridRow";
import moment from "moment";

const SummaryReportSearchForm = ({criteria, onDateChanged, clearCriteria, generateReport, errors}) => {
    let startDate = null;
    if(criteria.StartDate !== null)
        startDate = moment(criteria.StartDate).format("YYYY-MM-DD");

    let endDate = null;
    if(criteria.EndDate !== null)
        endDate = moment(criteria.EndDate).format("YYYY-MM-DD");

    return (
        <form className={"is-print-hidden"}>
            <GridRow rowClass="filter-bar" medium="2" large="4">
                <div>
                    <GridColumn>
                        <DateTimePickerField name="StartDate"
                                             label="Start date:"
                                             onChange={onDateChanged}
                                             showLabel
                                             value={startDate}
                                             error={(errors) ? errors.StartDate : ""}
                                             enableTime={false}
                                             isRequired={true}
                                             dateFormat={"m-j-Y"}
                        />
                    </GridColumn>
                    <GridColumn>
                        <DateTimePickerField name="EndDate"
                                             label="End date:"
                                             onChange={onDateChanged}
                                             showLabel
                                             value={endDate}
                                             error={(errors) ? errors.EndDate : ""}
                                             enableTime={false}
                                             isRequired={true}
                                             dateFormat={"m-j-Y"}
                        />
                    </GridColumn>
                </div>
                <div>
                    <ButtonBar>
                        <Button name="btnGenerateReport" onClick={generateReport} label="Generate Report"/>
                        <ButtonLink name="btnClearCriteria" onClick={clearCriteria} label="Clear"/>
                    </ButtonBar>
                </div>
            </GridRow>
        </form>
    );
};

SummaryReportSearchForm.propTypes = {
    criteria: PropTypes.object.isRequired,
    onDateChanged: PropTypes.func.isRequired,
    clearCriteria: PropTypes.func.isRequired,
    generateReport: PropTypes.func.isRequired,
    errors: PropTypes.object
};

export default SummaryReportSearchForm;