import * as actionTypes from "../constants/actionTypes";
import initialState from "./initialState";

export default function summaryReportReducer(state = initialState.summaryReport, action) {
    switch(action.type){
        case actionTypes.LOAD_SUMMARY_REPORT_DATA_AJAX_SUCCESS:
            return Object.assign({}, state, { summaryResults: action.data });
        case actionTypes.SAVE_SUMMARY_REPORT_SEARCH_CRITERIA:
            return Object.assign({}, state, { searchCriteria: action.criteria});
        default:
            return state;
    }
}