import React from 'react';
import PropTypes from "prop-types";
import Button from '../common/buttons/button';
import ButtonLink from '../common/buttons/buttonLink';
import {toDangerouslySetHtml } from '../common/htmlUtilities';
import UserForm from "./userForm";

const Profile = ({ isCreate, accountViewModel, generalProfileData, pageDirections, onClick, onCancel, onChange, isLoading, ajaxCallsLoading, errors }) => {

    let formEnabledClass = "two-col-form";
    let fieldSetEnabled = "";
    if(ajaxCallsLoading) {
        formEnabledClass += " disable-form";
        fieldSetEnabled = "disabled";
    }

    const cancelText = (isCreate) ? "Cancel" : "Return to the Course";
    const pageDir = <p dangerouslySetInnerHTML={toDangerouslySetHtml(pageDirections)} />; //eslint-disable-line react/no-danger
    return (
        <div>
            {pageDir}

            {
                (isCreate) &&
                <div className={"recaptcha_container"}>
                    <div id="recaptchav2" />
                </div>
            }

            <span className="text-required-legend text-danger">* are required fields</span>

            <ButtonLink name="btnCancelTop" onClick={onCancel} label={cancelText} />

            <hr />
            <hr className="spacer" />

            <form className={formEnabledClass}>
                <fieldset disabled={fieldSetEnabled}>
                    <UserForm
                        isCreate={isCreate}
                        accountViewModel={accountViewModel}
                        generalProfileData={generalProfileData}
                        onChange={onChange}
                        errors={errors} />

                    <div className="row">
                        <hr className="spacer" />
                    </div>
                    <ButtonLink
                        name="btnCancelBottom"
                        onClick={onCancel}
                        label={cancelText} />

                    <Button
                        name="btnProfile"
                        label="Submit"
                        onClick={onClick}
                        btnClass="input-green"
                        disabled={isLoading}
                    />
                </fieldset>
            </form>
        </div>
    );
};

Profile.propTypes = {
    isCreate: PropTypes.bool.isRequired,
    accountViewModel: PropTypes.object.isRequired,
    generalProfileData: PropTypes.object.isRequired,
    pageDirections: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    ajaxCallsLoading: PropTypes.bool.isRequired,
    errors: PropTypes.object
};

export default Profile;
