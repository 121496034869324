import * as config from "../constants/config";
import LocalStorage from "../components/shared/localStorage";
import Toastr from "toastr";
import ToastrOptions from "../constants/toastr";
import * as commonUtilities from "../components/common/commonUtilities";

export default class apiResponseHandler {
    constructor(data, callback, postMessage = null, additionalParams = {}){
        this.data = data;
        this.callback = callback;
        this.postMessage = postMessage;
        this.additionalParams = Object.assign({}, {letContainerDisplayError: true, onHidden: null }, additionalParams);
    }

    successResponseHandler() {
        Toastr.options = ToastrOptions.Error();
        this.postMessage ? Toastr.success(this.postMessage, () => Toastr.clear()) : null;
        if(this.data)
            this.callback(this.data);
        else
            this.callback();
    }

    errorResponseHandler() {
        Toastr.options = ToastrOptions.Error();
        let issueObj = {};
        let toastrMsg = "";
        let useRefreshToken = false;

        if (this.data.status === 500) {
            toastrMsg = `There was an error while attempting to fetch the information.  Please try again.  If the problem persists, contact ${config.supportEmail}.`;
        }
        else if(this.data.status === 404 || this.data.status === 401) {
            if(LocalStorage.isRefreshable()) {
                LocalStorage.removeAccessToken();
                useRefreshToken = true;
            }
            else {
                LocalStorage.logout();
                Toastr.error(`Your session has expired.  Please login again.  If the problem persists, contact ${config.supportEmail}.`, '', {
                    onHidden: () => {
                        window.location.href = "/";
                    }
                });
            }
        }
        else if (!this.data.responseJSON && typeof this.data.responseText === "string") {
            if(this.additionalParams.letContainerDisplayError)
                toastrMsg = `There was an error processing this request - ${this.data.responseText}`;
            else
                Toastr.error(this.data.responseText, '', { onHidden: (this.additionalParams.onHidden) ? this.additionalParams.onHidden : null});
        }
        else if (this.data.responseJSON) {
            let errorList = this.data.responseJSON;
            toastrMsg = "";
            if(!!errorList.TotalErrors) {
                for(let error of errorList.Errors) {
                    toastrMsg += error.Message;
                }
            }
            else {
                toastrMsg = `There was an error processing this request.  Please review errors in the form below.`;
                for (let error in errorList) {
                    if (errorList.hasOwnProperty(error)) {
                        let propNameArr = error.split(".");
                        let propName = propNameArr[propNameArr.length - 1];
                        issueObj[propName] = errorList[error].toString();
                    }
                }
            }
        }
        else {
            toastrMsg = `There was an error with the last action completed.  Please try again.  If the problem persists, contact ${config.supportEmail}.`;
        }

        const returnInfo = commonUtilities.isObjectNullOrEmpty(issueObj)
            ? toastrMsg
            :   {
                    toastrMsg: toastrMsg,
                    errors: issueObj
                };

        this.callback(useRefreshToken ? config.useRefreshToken : returnInfo);
    }
}