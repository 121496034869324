let accounts = null;

function getAccounts() {
    if(accounts === null) {
        accounts = [
            {
                Id: 7,
                Email: "testAccount@fcim.org",
                FirstName: "Fcim",
                LastName: "Test",
                Password: "123",
                isadmin: false,
                Role: "User",
                RoleId: 1,
                DivisionId: 4,
                Division: "D Division",
                IsContractor: 1,
                Contractor: "Yes",
                Courses: [
                    {
                        Acronym: "sat", CourseName: "Security Awareness Training", DateCompleted: "2017-07-19T15:54:38.1670000",
                        Sections: [
                            {SectionId: "EntireCourse", DateCompleted: "2017-07-14T15:54:38.1670000"}
                        ]
                    }
                ]
            },
            {
                Id: 8,
                Email: "min@fcim.org",
                FirstName: "",
                LastName: "",
                Password: "123",
                isadmin: false,
                Role: "User",
                RoleId: 1,
                DivisionId: 4,
                Division: "D Division",
                IsContractor: 0,
                Contractor: "No",
                Courses: [
                    {
                        Acronym: "sat", CourseName: "Security Awareness Training", DateCompleted: null,
                        Sections: [
                            {SectionId: "EntireCourse", DateCompleted: null}
                        ]
                    }
                ]
            },
            {
                Id: 1001,
                Email: "sysadmin@fcim.org",
                FirstName: "Fcim",
                LastName: "SysAdmin",
                Password: "123",
                isadmin: true,
                Role: "System Admin",
                RoleId: 2,
                DivisionId: 1,
                Division: "A Division",
                IsContractor: 1,
                Contractor: "Yes",
                Courses: [
                    {
                        Acronym: "sat", CourseName: "Security Awareness Training", DateCompleted: null,
                        Sections: [
                            {SectionId: "EntireCourse", DateCompleted: null}
                        ]
                    }
                ]
            },
            {
                Id: 1002,
                Email: "admin@fcim.org",
                FirstName: "Fcim",
                LastName: "Admin",
                Password: "123",
                isadmin: true,
                Role: "Admin",
                RoleId: 3,
                DivisionId: 1,
                Division: "A Division",
                IsContractor: 1,
                Contractor: "Yes",
                Courses: [
                    {
                        Acronym: "sat", CourseName: "Security Awareness Training", DateCompleted: null,
                        Sections: [
                            {SectionId: "EntireCourse", DateCompleted: null}
                        ]
                    }
                ]
            },
            {
                Id: 1003,
                Email: "viewonly@fcim.org",
                FirstName: "Fcim",
                LastName: "viewonly",
                Password: "123",
                isadmin: true,
                Role: "View Only Admin",
                RoleId: 4,
                DivisionId: 1,
                Division: "A Division",
                IsContractor: 1,
                Contractor: "Yes",
                Courses: [
                    {
                        Acronym: "sat", CourseName: "Security Awareness Training", DateCompleted: null,
                        Sections: [
                            {SectionId: "EntireCourse", DateCompleted: null}
                        ]
                    }
                ]
            }
        ];
    }

    return accounts;
}

export {getAccounts};

function saveAccounts(newAccountList) {
    accounts = newAccountList;
}

export {saveAccounts};

function convertCourseDataToAccountViewModel(userAcct) {
    let accViewModel = {
        Id: userAcct.Id,
        Email: userAcct.Email,
        FirstName: userAcct.FirstName,
        LastName: userAcct.LastName,
        RoleId: userAcct.RoleId,

        EmployeeType: userAcct.EmployeeType,
        IsContractor: userAcct.IsContractor,
        IsDistrictEmployee: userAcct.IsDistrictEmployee,
        IsDoeEmployee: userAcct.IsDoeEmployee,
        DivisionId: userAcct.DivisionId,
        DistrictId: userAcct.DistrictId
    };
    for(let i = 0; i < userAcct.Courses.length; i++)
    {
        accViewModel[userAcct.Courses[i].Acronym + "CompletionDate"] = userAcct.Courses[i].DateCompleted;
        for(let j = 0; j < userAcct.Courses[i].Sections.length; j++)
        {
            accViewModel[userAcct.Courses[i].Sections[j].SectionId + "CompletionDate"] = userAcct.Courses[i].Sections[j].DateCompleted;
        }
    }

    return accViewModel;
}

export {convertCourseDataToAccountViewModel};

function convertViewModelToCourseData(accViewModel, allCourses) {
    return  Object.assign({}, accViewModel, {
        Courses:
            allCourses.map((course) => {
                return {
                    Acronym: course.Acronym, CourseName: course.CourseName, DateCompleted: accViewModel[course.Acronym + "CompletionDate"],
                    Sections:
                        course.SectionIds.map((sectionId) => {
                            return { SectionId: sectionId, DateCompleted: accViewModel[sectionId + "CompletionDate"] };
                        })

                };
            })
    });
}

export {convertViewModelToCourseData};