import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import * as layoutActions from '../../actions/layoutActions';
import {Link} from "react-router-dom";
import * as config from "../../constants/config";
import Allow from "../../containers/authenticate/allow";
import * as PolicyEvents from "../../constants/policyEvents";

export class AdminMenu extends React.Component {
    componentDidMount() {
        this.props.actions.updatePageTitle("Admin Menu");
    }

    render() {
        const courseContentUrl = (process.env.NODE_ENV === 'production') ? config.courseLandingPage : `/#${config.courseLandingPage}`;
        return (
            <div className={"admin-menu"}>
                <p><a href={courseContentUrl} rel="noopener noreferrer" target="_blank" className="button-link indigo">Course Content</a> - Opens the course in a new tab. Be sure to <strong>CLOSE</strong> the
                    course tab to return to the Admin Menu.</p>
                <Allow policyEvent={PolicyEvents.MANAGE}>
                    <p><Link to="/admin/search" className="button-link indigo">User Search</Link> - Search for users by name, email, affiliation, affiliation type, or user/admin role.</p>
                </Allow>
                <Allow>
                    <p><Link to="/admin/search" className="button-link indigo">User Search</Link> - Search for users by name, email, affiliation, or affiliation type.</p>
                </Allow>
                <p><Link to="/reports/divisionReport" className="button-link indigo">Affiliation Report</Link> - Generate a report of completers (with completion date) by affiliation.</p>
                <Allow policyEvent={PolicyEvents.VIEW_SUMMARY_REPORT}>
                    <p><Link to="/reports/summaryReport" className="button-link indigo">General Course Information Report</Link> - Generate a summary of the number of users per division, district and affiliation type,
                        number of completers, and knowledge check item performance. </p>
                </Allow>
                <p><Link to="/" className="button-link indigo">Logout</Link></p>
            </div>
        );
    }
}

AdminMenu.propTypes = {
    actions: PropTypes.object.isRequired
};

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(layoutActions, dispatch)
    };
}

export default connect(null, mapDispatchToProps) (AdminMenu);