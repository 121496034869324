import manageUserApi from "../api/manageUserApi";
import { beginAjaxCall, ajaxCallError } from "./ajaxStatusActions";
import * as actionTypes from "../constants/actionTypes";
import * as config from "../constants/config";
import {refreshLogin} from "./authenticationActions";

export function loadUserSuccess(userInfo) {
    return { type: actionTypes.LOAD_USER_PROFILE_AJAX_SUCCESS, userInfo };
}

export function loadUser(userId) {
    return dispatch => {
        dispatch(beginAjaxCall());
        return manageUserApi.getUser(userId)
            .then((userInfo) => {
                dispatch(loadUserSuccess(userInfo));
            })
            .catch(error => {
                if(config.useRefreshToken === error) {
                    dispatch(refreshLogin())
                        .then(() => {
                            dispatch(loadUser(userId));
                        });
                }
                else {
                    dispatch(ajaxCallError(error));
                    throw (error);
                }
            });
    };
}

export function updateUserSuccess(userInfo) {
    return { type: actionTypes.UPDATE_PROFILE_AJAX_SUCCESS, userInfo };
}

export function updateUser(accountViewModel, allCourses) {
    return dispatch => {
        dispatch(beginAjaxCall());
        return manageUserApi.updateUser(accountViewModel, allCourses)
            .then(() => {
                dispatch(updateUserSuccess(accountViewModel));
            })
            .catch(error => {
                if(config.useRefreshToken === error) {
                    dispatch(refreshLogin())
                        .then(() => {
                            dispatch(updateUser(accountViewModel, allCourses));
                        });
                }
                else {
                    dispatch(ajaxCallError(error));
                    throw (error);
                }
            });
    };
}

export function loadUsersSuccess(data){
    return { type: actionTypes.LOAD_USERS_AJAX_SUCCESS, data };
}

export function loadUsers(filterCriteria){
    return dispatch => {
        dispatch(beginAjaxCall());

        return manageUserApi.getUsers(filterCriteria)
            .then(data =>
            {
                dispatch(loadUsersSuccess(data));
            })
            .catch((error) =>
                {
                    if(config.useRefreshToken === error) {
                        dispatch(refreshLogin())
                            .then(() => {
                                dispatch(loadUsers(filterCriteria));
                            });
                    }
                    else {
                        dispatch(ajaxCallError(error));
                        throw (error);
                    }
                }
            );
    };
}

export function saveSearchCriteria(criteria) {
    return { type: actionTypes.SAVE_SEARCH_CRITERIA, criteria};
}
