
import React from "react";
import PropTypes from "prop-types";

const RadioField = ({ name, label, showLabel, inputClass, onChange, value, error, options,isRequired }) => {
    let labelClass = "is-visuallyhidden";
    if (showLabel)
        labelClass = "";

    let wrapperClass = '';
    if (error && error.length > 0)
        wrapperClass = 'has-error';

    let radioListOptions = [];
    if(options && options.length > 0)
        radioListOptions  = options;

    if(value === null){
        value = "";
    }

    if(inputClass === undefined)
        inputClass = "";

    return (
        <div className={"row " + wrapperClass}>
            <fieldset>
                <legend htmlFor={name} className={"column-1 " + labelClass}>{ (isRequired) && <span className="text-danger">* </span>}{label}</legend>
                <div className={"column-2 " + inputClass}>
                {
                    radioListOptions.map((option, index) => {
                            return (
                            <div key={index}>
                                <label key={index}>
                                    <input type="radio" id={name + index} name={name} value={option.value} onChange={onChange} checked={option.value === value} /> <span>{option.text}</span>
                                </label>
                            </div>);
                    })
                }
                </div>
            </fieldset>
            {error && <div className="text-danger">{error}</div>}
        </div>
    );
};

RadioField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    error: PropTypes.string,
    inputClass: PropTypes.string,
    showLabel: PropTypes.bool,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    isRequired: PropTypes.bool
};

RadioField.defaultProps = {
    isRequired: false
};

export default RadioField;